const appSyncConfig = {
  aws_appsync_graphqlEndpoint:
    process.env.GRAPHQL_ENDPOINT ||
    "https://o6kozbuxbnd5bi2sjicbw5glfm.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: process.env.REGION || "us-east-1",
  aws_appsync_authenticationType:
    process.env.AUTH_TYPE || "AMAZON_COGNITO_USER_POOLS",
  apiKey: "da2-dclvzswq5zc7tonre5ficeurly",
  aws_appsync_graphqlEndpoint_email:
    "https://dp3qyvwqwjgdteme7bb6b3ruva.appsync-api.us-east-1.amazonaws.com/graphql",
};

export default appSyncConfig;
