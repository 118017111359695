import { gql } from "@apollo/client";

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: ProjectUpdateInput) {
    UpdateProject(input: $input) {
      message
      success
      result
    }
  }
`;
