import { setProjects } from "../set/setProjects";
import { graphQLFetch } from "../../../utils";
import { GET_PROJECTS } from "../../queries";

export const getProjects =
  ({ companyId }) =>
  async (dispatch, getState) => {
    const data = await graphQLFetch(
      GET_PROJECTS,
      { cId: companyId },
      "query",
      getState
    );

    const { GetProjectsByCompanyId } = data || {};
    const newItems =
      Array.isArray(GetProjectsByCompanyId) && GetProjectsByCompanyId.length > 0
        ? formatArr(GetProjectsByCompanyId)
        : [];
    const projects = {
      projects: newItems,
    };

    return dispatch(setProjects(projects || []));
  };

export const formatArr = (items) => {
  return items
    .filter((item) => item)
    .sort((a, b) => {
      const nameA = a.pName.toUpperCase();
      const nameB = b.pName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
};
