export const Color = {
  primary: "#001F3F",
  secondary: "#2ECC71", 
  error: "#BB1328",
  outlineVarient: "#979CA0",
  hyperlink: "#007BFF",
  white: "#fff",
  darkBlue: "#051233",
  gray:"#bdc3c7",
  darkGray:'#242424'
};
