import { gql } from "@apollo/client";

export const UPDATE_BUDGET_ITEM = gql`
  mutation updateBudgetItem($input: BudgetUpdateInput) {
    UpdateBudgetItem(input: $input) {
      message
      success
      result
    }
  }
`;
