import { graphQLFetch } from "../../../utils";
import { GET_ALL_COMPANIES } from "../../queries";
import { setAllCompanies } from "../set/setAllCompanies";

export const getAllCompanies =
  (callback = () => {}) =>
  async (dispatch, getState) => {
    const data = await graphQLFetch(
      GET_ALL_COMPANIES,
      undefined,
      "query",
      getState
    );
    const { getAllCompanies } = data || {};
    callback();
    return dispatch(setAllCompanies(getAllCompanies || []));
  };
