import React from "react";
import { StyledButton } from "./Button.styles";
import ClipLoader from "react-spinners/ClipLoader";
import { Color } from "../../styles/Colors";

export const Button = (props: any) => {
  const {
    children,
    hasStartIcon = false,
    onClick,
    disabled,
    type,
    className,
    active,
    href,
    isLoading,
  } = props || {};

  const getClassName = (className, disabled, active) => {
    const names: any = [];
    if (className) names.push(className);
    if (disabled) names.push("disabled");
    if (active) names.push("active");
    return names.join(" ");
  };

  return (
    <StyledButton
      aria-busy={isLoading ? true : undefined}
      className={getClassName(className, disabled, active)}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      type={type}
    >
      {isLoading && (
        <ClipLoader
          color={Color.white}
          size={15}
          cssOverride={{
            marginLeft: "17px",
            marginRight: "17px",
            marginTop: "2px",
            marginBottom: "2px",
          }}
        />
      )}
      {!isLoading && hasStartIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M20 13H13V20H11V13H4V11H11V4H13V11H20V13Z" fill="white" />
        </svg>
      )}
      {!isLoading && children}
    </StyledButton>
  );
};

export default Button;
