import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const InputBox = styled.div<{
  errorMessage?: boolean;
  isInputArea?: boolean;
}>`
  display: flex;
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? Color.outlineVarient : Color.error)};
  position: relative;
  align-items: center;
  padding: 0px 12px;
  gap: 4px;
  border-radius: 4px;
  height: ${({ isInputArea }) => (isInputArea ? "112px" : "40px")};

  img {
    width: 24px;
    height: 24px;
  }

  input {
    border: none;
    color: ${Color.darkGray};
    font-size: 16px;
    font-family: "Lato";
    width: 100%;
    background-color: ${Color.white};

    &::placeholder {
      color: #666666;
    }
    &:focus {
      outline: none;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const InputFieldContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const ErrorMessage = styled.p<{ errorMessageSize: string }>`
  color: ${Color.error} !important;
  font-size: ${({ errorMessageSize }) =>
    errorMessageSize ? errorMessageSize : "14px"} !important;
  pointer-events: none;
  padding-left: 8px;
  padding-top: 4px;
`;

export const CustomLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #686b6d;
  text-align: left;

  &.required:after {
    content: " *";
  }

  img {
    position: relative;
    top: 3px;
    left: 3px;
  }
`;

export const InputEndIcon = styled.img`
  width: 22px !important;
  height: 15px !important;
`;

export const InputBoxContainer = styled.div`
min-height: 46px;
position: relative;
`;
