import { SET_PROJECTS_BY_ID } from "../../constants";

export const projectById = (
  state: any = {
    isProjectLoading: true,
  },
  action: any
) => {
  switch (action.type) {
    case SET_PROJECTS_BY_ID:
      return { ...action.payload, isProjectLoading: false };
    default:
      return state;
  }
};
