import { gql } from "@apollo/client";

export const SEND_EMAIL_QUERY = gql`
  mutation SendEmail($input: ContactUsInput) {
    SendEmail(input: $input) {
      statusCode
      body
    }
  }
`;
