import styled from "styled-components";
import { Color } from "../../styles/Colors";


export const Conatiner = styled.div`
  .modal {
    padding: 0px;
    width: 800px;
  }
  .modal-header {
    padding: 0px;
  }
  .modal-body {
    overflow-y: auto;
    padding: 24px;
    min-height: 65vh;
    max-height: 70vh;
  }

  .modal-footer {
    padding: 0px;

    z-index: 999;
  }
`;

export const dropzoneStyles = {
    border: '2px dashed #cccccc',
    padding: '50px',
    textAlign: 'center',
    cursor: 'pointer',
};
  
export const containerStyle = {
    display: 'flex',
    justifyContent: 'center',  
};
  
export const buttonStyle = {
    margin: '10px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    
};

export const removeButtonStyle = {
    color: '#fff',
    backgroundColor: 'rgb(13 13 13)',
    border: 'none',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '0',
  };
  
export const fileListItemStyle = {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: '10px 0',
    width: '100%',
  };

export const fileListContainerStyle = {
    maxHeight: '300px', 
    overflowY: 'auto',   
    marginTop: '10px',
    border: '1px solid #cccccc',  
    borderRadius: '4px',
    padding: '10px',
};
export const acceptedFormatsText = {
    color: 'red',
    padding: '5px',
    fontSize: '10px',
};

export const Header = styled.div`
  display: flex;
  gap: 16px;
  box-shadow: 0px 4px 21px 0px ${Color.primary}17;
  width: 100%;
  padding: 16px;
`;
export const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: "Lato", "Roboto";
  color: ${Color.primary};
`;

export const SubHeading = styled(Heading)`
  font-size: 16px;
  color: #686b6d;
  line-height: 19.2px;
`;
export const CloseButton = styled.span`
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;
