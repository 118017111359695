import styled from "styled-components";

export const Container = styled.div<{ isCreateUserPage: boolean }>`
  display: flex;
  min-width: 100%;
  height: calc(
    100vh - ${({ isCreateUserPage }) => (isCreateUserPage ? '0' : '139')}px
  );
  > div {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;

export const MainSection = styled.div`
  height: 100vh;
  overflow: hidden;
  > div {
    height: 100%;
    overflow: auto;
  }
`;

