import { setUnits } from "../set/setUnits";
import { graphQLFetch } from "../../../utils";
import { GET_UNITS } from "../../queries";

export const getUnits =
  ({ pId }, callback) =>
  async (dispatch, getState) => {
    const data = await graphQLFetch(GET_UNITS, { pId: pId }, "query", getState);

    const { GetProjUnits } = data || {};
    const newItems =
      Array.isArray(GetProjUnits) && GetProjUnits.length > 0
        ? formatArr(GetProjUnits)
        : [];

    const items = {
      units: newItems,
    };
    callback(newItems);
    return dispatch(setUnits(items || []));
  };

export const formatArr = (items) => {
  return items.filter((item) => item);
};
