import React from "react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import LevelLogo from "../../assets/images/Common/Vertical_iq.png";
import KeyLogo from "../../assets/images/Common/KeyIcon.png";
import PasswordIcon from "../../assets/images/Common/Password_icon.svg";
import SucessIcon from "../../assets/images/Common/check_circle.png";

import { useNavigate } from "react-router-dom";
import { verifiedEmailSelector } from "../../redux";

import InputField from "../InputField/InputField";
import Button from "../Button";
import {
  Container,
  CustomHeader,
  UserText,
  UserFields,
  CustomForm,
  UserBtn,
  LogoBox,
  MainContent,
  ContentBox,
  PasswordInformation,
  SucessInformation,
} from "./ResetPassword.styles";
import { BackgroundImageConatiner } from "../Login/Login.styles";
import { verifyForgetPassword } from "../../utils/cognito-services";
import {
  hasAnyEmptyFiled,
  hasAnyErrorStatusFalse,
  isValidPassword,
} from "../../utils";
import { Color } from "../../styles/Colors";
const input = {
  code: "",
  password: "",
  confirmPassword: "",
};
const inputError = {
  code: { status: true, message: "" },
  password: { status: true, message: "" },
  confirmPassword: { status: true, message: "" },
};

export const ResetPassword = () => {
  const [inputValues, setInputValues] = React.useState({ ...input });
  const userEmail = useSelector(verifiedEmailSelector);
  const [errorMessage, setErrorMessage] = React.useState(inputError);
  const [showSucessModal, setSucessModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigateTo = useNavigate();

  const onChangeHandler = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = (event) => {
    const name = event.target.name;
    const value = inputValues[name];
    if (name === "code") {
      setErrorMessage({
        ...errorMessage,
        code: {
          status: value.length === 6 && !isNaN(value),
          message:
            value.length === 6 && !isNaN(value)
              ? ""
              : "Code must be of 6 digits",
        },
      });
    } else if (
      inputValues.password &&
      inputValues.confirmPassword &&
      inputValues.password !== inputValues.confirmPassword
    ) {
      setErrorMessage({
        ...errorMessage,
        password: { status: true, message: "" },
        confirmPassword: {
          status: false,
          message: "Password and confirm password do not match",
        },
      });
    } else {
      const { status, message } = isValidPassword(value);
      setErrorMessage({
        ...errorMessage,
        [name]: {
          status,
          message: status ? "" : "Password Requirements not met",
        },
      });
    }
  };

  const resetPasswordHandler = () => {
    const { code, password, confirmPassword } = inputValues;
    if (
      !hasAnyErrorStatusFalse(errorMessage) &&
      !hasAnyEmptyFiled(inputValues)
    ) {
      setIsLoading(true);
      verifyForgetPassword(userEmail, code, confirmPassword, (message) => {
        if (message === "SUCCESS") {
          setSucessModal(true);
        } else {
          setErrorMessage({
            ...errorMessage,
            code: { status: true, message: "" },
            password: { status: true, message: "" },
            confirmPassword: {
              status: true,
              message: "Invalid code provided, please request a code again.",
            },
          });
        }
        setIsLoading(false);
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        code: {
          status: code !== "" && errorMessage.code.status,
          message: errorMessage.code.message,
        },
        password: {
          status: password !== "" && errorMessage.password.status,
          message: errorMessage.password.message,
        },
        confirmPassword: {
          status: confirmPassword !== "" && errorMessage.confirmPassword.status,
          message: errorMessage.password.message,
        },
      });
    }
  };

  const ResetPasswordSucess = () => {
    return (
      <SucessInformation>
        <img src={SucessIcon} alt="sucess" />
        <p>
          Your password has been successfully reset. You can now log in using
          your new password.
        </p>
        <UserBtn>
          <Button
            className="btn-primary"
            type="primary"
            onClick={() => {
              navigateTo("/login");
            }}
          >
            Go to Login
          </Button>
        </UserBtn>
      </SucessInformation>
    );
  };

  return (
    <>
      <BackgroundImageConatiner></BackgroundImageConatiner>
      <Container>
        <ContentBox>
          <CustomHeader>
            <LogoBox>
              <img src={LevelLogo} alt="level" />
            </LogoBox>
            {showSucessModal ? (
              <ResetPasswordSucess />
            ) : (
              <>
                <UserText>Reset Your Password</UserText>
                <p>
                  We have sent a password reset code by email to ***@***. Enter
                  it below to reset your password
                </p>
              </>
            )}
          </CustomHeader>
          {!showSucessModal && (
            <MainContent>
              <CustomForm>
                <UserFields>
                  <InputField
                    value={inputValues["code"]}
                    name={"code"}
                    type="password"
                    showPlaceHolder
                    placeholder="Enter Code"
                    InputIcon={KeyLogo}
                    onChange={onChangeHandler}
                    onBlur={handleBlur}
                    errorMessage={errorMessage.code}
                    maxLength={6}
                  />
                  <InputField
                    name={"password"}
                    type="password"
                    showPlaceHolder
                    placeholder="New Password"
                    InputIcon={PasswordIcon}
                    onChange={onChangeHandler}
                    onBlur={handleBlur}
                    errorMessage={errorMessage.password}
                    maxLength={16}
                  />
                  <InputField
                    name={"confirmPassword"}
                    type="password"
                    showPlaceHolder
                    placeholder="Confirm Password"
                    InputIcon={PasswordIcon}
                    onChange={onChangeHandler}
                    passwordStyle="square"
                    onBlur={handleBlur}
                    errorMessage={errorMessage.confirmPassword}
                    maxLength={16}
                  />
                </UserFields>
                <UserBtn>
                  <Button type="primary" onClick={resetPasswordHandler}>
                    {isLoading ? (
                      <ClipLoader
                        color={Color.white}
                        size={15}
                        cssOverride={{
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </UserBtn>
              </CustomForm>
              <PasswordInformation>
                <p>Password Requirements</p>
                <ul>
                  <li>Length: 7-16 characters</li>
                  <li>At least 1 number</li>
                  <li>At least 1 special character</li>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 lowercase letter</li>
                </ul>
              </PasswordInformation>
            </MainContent>
          )}
        </ContentBox>
      </Container>
    </>
  );
};

export default ResetPassword;
