import { SET_APOLLO_CLIENT } from "../../constants";

export const apolloClient = (state: any = null, action: any) => {
  switch (action.type) {
    case SET_APOLLO_CLIENT:
      return action.payload;
    default:
      return state;
  }
};
