import { graphQLFetch } from "../../../utils";
import { DELETE_INSPECTION } from "../../queries";

export const deleteInspection =
  ({ inspId, pId }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      inspId,
      pId,
    };

    const data = await graphQLFetch(
      DELETE_INSPECTION,
      variables,
      "mutation",
      getState
    );
    const { DelInsp } = data || {};
    callback(DelInsp);
  };
