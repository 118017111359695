import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const StyledButton = styled.button<{ isLoading?: boolean }>`
  display: flex;
  gap: 8px;
  align-self: center;
  padding: 13px 24px;
  border: 2px solid;
  background-color: ${Color.primary};
  font-family: Roboto, "lato", sans-serif;
  color: ${Color.white};
  font: 500 16px "Roboto", "lato", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  ${({ isLoading }) =>
    isLoading &&
    `pointer-events: none;
  `}

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.btn-primary {
    color: ${Color.white};
    background-color: ${Color.primary};
    border-color: ${Color.primary};

    &:hover,
    &:focus {
      background-color: #4170a0 !important;
      color: white !important;
    }
  }

  &.btn-default {
    color: ${Color.primary};
    background-color: ${Color.white};
    border-color: #ccc;
    border: 2px solid ${Color.primary};
    font-weight: 600;

    &:hover,
    &:focus {
      background-color: #ccc !important;
      color: ${Color.primary} !important;
    }
  }
`;
