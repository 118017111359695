import { graphQLFetch } from "../../../utils";
import { SEND_EMAIL_QUERY } from "../../queries";

export const sendEmail =
  ({ items }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      input: items,
    };
    const data = await graphQLFetch(
      SEND_EMAIL_QUERY,
      variables,
      "mutation",
      getState,
      true
    );

    const { SendEmail } = data || {};
    callback(SendEmail);
  };
