// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  HeaderSection,
  PageContainer,
  Type,
  MainContainer,
  BackButton,
  ActionButtons,
} from "./CreateProject.styles";
import {
  formatObjToKeyValuePair,
  hasAnyErrorStatusFalse,
  projectFields,
} from "../../utils";
import ClipLoader from "react-spinners/ClipLoader";
import BackIcon from "../../assets/images/Common/back-icon.png";
import Button from "../Button/Button";
import { createProject } from "../../redux";
import { toast } from "react-toastify";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";
import UserInputFields from "../UserInputFields/UserInputFields";
import { PROJECT_DEFAULT_INPUT_ERROR } from "../../constants";
import { Color } from "../../styles/Colors";

export const CreateProject = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValues, setInputValues] = useState({});
  const [errorMessage, setErrorMessage] = useState(PROJECT_DEFAULT_INPUT_ERROR);
  const { companyId } = useParams();

  const navigate = useNavigate();

  const saveProjectDetails = () => {
    const items = formatObjToKeyValuePair(inputValues);

    let modifiedError = errorMessage;
    for (let inputFieldName in projectFields) {
      const { required } = projectFields[inputFieldName];
      const value = inputValues[inputFieldName]?.value;
      if (required && (!value || value === "")) {
        modifiedError = {
          ...modifiedError,
          [inputFieldName]: {
            ...modifiedError[inputFieldName],
            status: false,
            message:
              inputFieldName === "Project Type"
                ? "Select Project Type"
                : "Required field",
          },
        };
      }
    }

    setErrorMessage(modifiedError);
    if (
      !hasAnyErrorStatusFalse(modifiedError) &&
      !hasAnyErrorStatusFalse(errorMessage)
    ) {
      setIsLoading(true);
      dispatch(
        createProject(
          { items: { ...items, cId: companyId } },
          (projectInfo) => {
            const { message, success } = projectInfo || {};
            if (success) {
              notifySuccess(message, () => {
                setIsLoading(false);
                navigateToProjects();
              });
            } else {
              setIsLoading(false);
              notifyError("Not able to create project");
            }
          }
        )
      );
    }
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message, onclose = () => {}) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: onclose,
    });
  };

  const navigateToProjects = () => {
    navigate(`/home/${companyId}`);
  };

  return (
    <>
      <StyledToast />
      <PageContainer>
        <MainContainer>
          <HeaderSection>
            <BackButton onClick={navigateToProjects}>
              <img src={BackIcon} alt="level" />
            </BackButton>
            <Type>Create Project</Type>
            <ActionButtons>
              <Button
                type="button"
                className="btn-default"
                onClick={navigateToProjects}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn-primary"
                onClick={saveProjectDetails}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ClipLoader
                    color={Color.white}
                    size={15}
                    cssOverride={{
                      marginLeft: "17px",
                      marginRight: "17px",
                      marginTop: "2px",
                      marginBottom: "2px",
                    }}
                  />
                ) : (
                  "Save Project"
                )}
              </Button>
            </ActionButtons>
          </HeaderSection>
          <UserInputFields
            page="CreateProject"
            projectFields={projectFields}
            setInputValues={setInputValues}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </MainContainer>
      </PageContainer>
    </>
  );
};

export default CreateProject;
