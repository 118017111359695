import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SidebarConatiner, SidebarButton } from "./Sidebar.styles";
import { userInfoSelector } from "../../redux";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [selectedButton, setSelectedButton] = useState(0);
  const userInfo = useSelector(userInfoSelector) || {};

  const { signInUserSession } = userInfo || {};

  const { idToken } = signInUserSession || {};
  const { payload } = idToken || {};
  const { "custom:companyId": companyId } = payload || {};
  const ProjectIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M4.5 32V11H10.5V5H25.5V17H31.5V32H19.5V26H16.5V32H4.5ZM7.5 29H10.5V26H7.5V29ZM7.5 23H10.5V20H7.5V23ZM7.5 17H10.5V14H7.5V17ZM13.5 23H16.5V20H13.5V23ZM13.5 17H16.5V14H13.5V17ZM13.5 11H16.5V8H13.5V11ZM19.5 23H22.5V20H19.5V23ZM19.5 17H22.5V14H19.5V17ZM19.5 11H22.5V8H19.5V11ZM25.5 29H28.5V26H25.5V29ZM25.5 23H28.5V20H25.5V23Z"
          fill="black"
        />
      </svg>
    );
  };

  const AnalyticIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M3 32V29H33V32H3ZM4.5 27.5V17H9V27.5H4.5ZM12 27.5V9.5H16.5V27.5H12ZM19.5 27.5V14H24V27.5H19.5ZM27 27.5V5H31.5V27.5H27Z"
          fill="black"
        />
      </svg>
    );
  };

  const SideButton = ({
    title,
    Icon,
    index,
  }: {
    title: string;
    Icon: any;
    index: number;
  }) => {
    return (
      <SidebarButton
        onClick={() => {
          setSelectedButton(index);
        }}
      >
        <span>
          <Icon />
        </span>
        <p>{title}</p>
      </SidebarButton>
    );
  };

  return (
    <SidebarConatiner buttonIndex={selectedButton}>
      <Link to={`/home/${companyId || ""}`}>
        <SideButton title={"Projects"} Icon={ProjectIcon} index={0} />
      </Link>
      <Link to="analytics">
        <SideButton title={"Analytics"} Icon={AnalyticIcon} index={1} />
      </Link>
    </SidebarConatiner>
  );
};

export default Sidebar;
