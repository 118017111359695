import React, { useState } from "react";
import Tabs from "../Tabs/Tabs";
import AnalyticsPreview from "./components/AnalyicsPreview/AnalyicsPreview";
import { Container } from "./Analytics.styles";
import { useDispatch, useSelector } from "react-redux";
import { getTableauToken, selectTableauToken } from "../../redux";
import { useThirdPartyCookieCheck } from "./components/AnalyicsPreview/iframeHook";
import { TabData } from '../Tabs/Tabs';

const Analytics = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { token, url } = useSelector(selectTableauToken);
  const isSupported = useThirdPartyCookieCheck();
  const cookiesEnabledMessage =
    "Cookies are disabled in this browser,Please enable cookies to view the reports";

  React.useEffect(() => {
    if (isSupported) {
      setToken();
    }
  }, [isSupported]);

  const setToken = async () => {
    setLoading(true);
    dispatch(getTableauToken()).then(({ payload }) => {
      const { body } = payload || {};
      setLoading(false);
    });
  };

  const onTabChange = () => {
    if (isSupported) {
      setToken();
    }
  };

  const tabData: TabData[] = [
    {
      label: "BAT",
      value: "1",
      content: (
        <AnalyticsPreview
          header={"View your budget compared to similar projects"}
          cookiesEnabledMessage={isSupported ? "" : cookiesEnabledMessage}
          src={url?.length > 0 ? url[0] : ""}
          token={token}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "Market IQ",
      value: "2",
      content: (
        <AnalyticsPreview
          header={"Real estate market context to your proposed project"}
          cookiesEnabledMessage={isSupported ? "" : cookiesEnabledMessage}
          src={url?.length > 0 ? url[1] : ""}
          token={token}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "RPM/EOC",
      value: "3",
      content: (
        <AnalyticsPreview
          header={"RPM/EOC"}
          cookiesEnabledMessage={isSupported ? "" : cookiesEnabledMessage}
          src={url?.length > 0 ? url[2] : ""}
          token={token}
          isLoading={isLoading}
        />
      ),
    },
  ];
  return (
    <Container>
      <Tabs
        tabData={tabData}
        onTabChange={onTabChange}
      />
    </Container>
  );
};

export default Analytics;
