import React from "react";

export const IconHome: React.FC<{ color?: string }> = ({
  color = "#666666",
}) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 31.5V10.5H10.5V4.5H25.5V16.5H31.5V31.5H19.5V25.5H16.5V31.5H4.5ZM7.5 28.5H10.5V25.5H7.5V28.5ZM7.5 22.5H10.5V19.5H7.5V22.5ZM7.5 16.5H10.5V13.5H7.5V16.5ZM13.5 22.5H16.5V19.5H13.5V22.5ZM13.5 16.5H16.5V13.5H13.5V16.5ZM13.5 10.5H16.5V7.5H13.5V10.5ZM19.5 22.5H22.5V19.5H19.5V22.5ZM19.5 16.5H22.5V13.5H19.5V16.5ZM19.5 10.5H22.5V7.5H19.5V10.5ZM25.5 28.5H28.5V25.5H25.5V28.5ZM25.5 22.5H28.5V19.5H25.5V22.5Z"
        fill={color}
      />
    </svg>
  );
};
export default IconHome;
