import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  ButtonContainer,
  CustomModalBody,
  CustomHeader,
} from "./ForgetModal.styles";

import Modal from "../../../Modal/Modal";
import InputField from "../../../InputField/InputField";
import Button from "../../../Button/Button";

import EmailIcon from "../../../../assets/images/Common/emailIcon.png";
import CheckCircle from "../../../../assets/images/Common/check_circle.png";
import { isValidEmail } from "../../../../utils";
import { forgotPassword } from "../../../../utils/cognito-services";
import { Color } from "../../../../styles/Colors";

const ForgetModal: React.FC<{
  forgetModal: boolean;
  setForgetModal: (value: boolean) => void;
  handleForget: () => void;
  isEmailSent: boolean;
  setEmailSent: (value: boolean) => void;
  sendVerificationEmail: (email: string) => void;
}> = ({
  forgetModal,
  setForgetModal,
  handleForget,
  isEmailSent,
  setEmailSent,
  sendVerificationEmail,
}) => {
  const [errorMessage, setErrorMessage] = useState({
    status: true,
    message: "",
  });
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (event, value) => {
    setEmail(value);
  };

  const handleOnBlur = () => {
    const { status, message } = isValidEmail(email);
    setErrorMessage({ status, message: status ? "" : message });
  };

  const handleForgetPassword = () => {
    const { status, message } = isValidEmail(email);
    setIsLoading(true);
    if (!status || email === "") {
      setErrorMessage({ status, message });
      setIsLoading(false);
    } else {
      forgotPassword(
        email,
        () => {
          setErrorMessage({ status, message: "" });
          setForgetModal(false);
          setEmailSent(true);
          setIsLoading(false);
        },
        () => {
          console.error("error");
          setErrorMessage({
            status: false,
            message: "Please enter a valid email address",
          });
          setIsLoading(false);
        }
      );
    }
  };
  const forgetFooter = (
    <ButtonContainer>
      <Button
        onClick={handleForgetPassword}
        className="btn-primary"
        type="button"
        isLoading={isLoading}
      >
        {isLoading ? (
          <ClipLoader
            color={Color.white}
            size={15}
            cssOverride={{ marginLeft: "10px", marginRight: "10px" }}
          />
        ) : (
          "Submit"
        )}
      </Button>
    </ButtonContainer>
  );

  const EmailHeader = (
    <CustomHeader>
      <img src={CheckCircle} alt="sent email" />
      <h2>Email sent</h2>
    </CustomHeader>
  );

  return (
    <div>
      {forgetModal && (
        <Modal
          open={handleForget}
          onClose={() => {
            setForgetModal(false);
            setErrorMessage({ status: true, message: "" });
          }}
          header={"Forgot Your Password?"}
          footer={forgetFooter}
        >
          <CustomModalBody>
            <p>
              Enter your email address below to receive instructions on how to
              reset your password.
            </p>
            <InputField
              name="email"
              placeholder="Enter email address"
              showPlaceHolder={true}
              InputIcon={EmailIcon}
              onChange={onChangeHandler}
              errorMessage={errorMessage}
              onBlur={handleOnBlur}
              maxLength={200}
              regexPattern={/^[^~]*$/}
            />
          </CustomModalBody>
        </Modal>
      )}
      {isEmailSent && (
        <Modal
          header={EmailHeader}
          open={() => setEmailSent(true)}
          onClose={() => setEmailSent(false)}
          footer={
            <ButtonContainer>
              <Button
                className="btn-primary"
                type="button"
                onClick={() => {
                  sendVerificationEmail(email);
                }}
              >
                Done
              </Button>
            </ButtonContainer>
          }
        >
          <p>
            Password reset instructions have been sent to your email address.
            Please check your inbox and follow the instructions to reset your
            password.
          </p>
        </Modal>
      )}
    </div>
  );
};

export default ForgetModal;
