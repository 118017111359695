import React from "react";
import { CardsContainer } from "./Homecards.style";

const Homecards: React.FC<{
  image: string;
  title: string;
  description: string;
}> = ({ image, title, description }) => {
  return (
    <CardsContainer>
      <img src={image} />
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </CardsContainer>
  );
};

export default Homecards;
