import { gql } from "@apollo/client";

export const CREATE_UNIT = gql`
  mutation putUnit($input: UnitInput!) {
    PutUnit(input: $input) {
      message
      success
      result
    }
  }
`;
