import { gql } from "@apollo/client";

export const DELETE_BUDGET_ITEM = gql`
  mutation deleteBudgetItem($bdgtLnItmId: ID!, $pId: ID!) {
    DeleteBudgetItem(bdgtLnItmId: $bdgtLnItmId, pId: $pId) {
      message
      success
      result
    }
  }
`;
