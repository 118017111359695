import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const StyledActionCard = styled.div<{ mediaPosition: string }>`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 8px;
  flex: 0 0 32%;
  background: ${Color.white};
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.07);
  &,
  &:focus,
  &:hover {
    color: grey;
    text-decoration: none;
  }
  .action-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .action-card-content {
    color: #666;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    line-break: anywhere;
  }

  .action-card-title {
    margin-bottom: 4px;
  }

  .action-card-media {
    display: flex;
    flex-shrink: 0;
    align-items: ${({ mediaPosition }) => mediaPosition};
  }

  .action-card-arrow {
    display: flex;
    align-items: center;
  }

  .action-card-skeleton {
    display: flex;
    .action-card-skeleton-media {
      flex-shrink: 0;
      display: flex;
      align-items: ${({ mediaPosition }) => mediaPosition};
    }
  }
`;

export const Type = styled.div`
  color: #333;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const License = styled.div`
  color: #666;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
