import React, { useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import Modal from "../Modal/Modal"; 
import { s3Config } from '../../utils/helpers/AwsConfig';
import { 
    buttonStyle,
    containerStyle,
    removeButtonStyle,
    fileListItemStyle,
    dropzoneStyles,
    acceptedFormatsText,
    fileListContainerStyle,
    Conatiner,
    Header,
    Heading,
    SubHeading,
    CloseButton
} from "./FileUpload.styles"

interface FileUploadPopupProps {
    open: boolean;
    onClose: () => void;
    singleFile?: boolean;
}

export const FileUpload = ({ open, onClose, singleFile = false }: FileUploadPopupProps) => {
    const [files, setFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState(false);
    const { companyId, projectId } = useParams();
    const s3FilePath = "https://lc-policy-dev.s3.amazonaws.com/Projects%2BTemplate%2B20240913.csv"
    const allowedTypes = [
        'text/csv',                                    
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
    ];

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const filteredFiles = acceptedFiles.filter(file => allowedTypes.includes(file.type));
        if (singleFile) {
          setFiles(filteredFiles.slice(0, 1));
        } else {
          setFiles(prevFiles => [...prevFiles, ...filteredFiles]);
        }
    }, [singleFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: !singleFile,
    });

    const notifyError = (message) =>
        toast.error(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
    });
    
    const notifySuccess = (message, onclose = () => {}) => {
        toast.success(message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: onclose,
        });
    };
    

    const removeFile = (fileToRemove: File) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };

    const uploadFiles = async () => {
        if (files.length === 0) {
            alert('No files selected.');
            return;
        }

        setUploading(true);

        const { accessKeyId, secretAccessKey, region, bucket } = s3Config();

        const S3_BUCKET = bucket;
    
        AWS.config.update({
            accessKeyId,
            secretAccessKey,
            region,
        });

        const s3 = new S3({
            params: { Bucket: S3_BUCKET },
            region,
        });

        const folder = projectId ? `${companyId}/PROJECTS/${projectId}/` : `${companyId}/`
        const subFolder = `INBOX/`;

        const checkFolderExists = async (folderPath) => {
            const params = {
                Bucket: S3_BUCKET,
                Prefix: folderPath, 
                Delimiter: '/',
                MaxKeys: 1
            }
            const data = await s3.listObjectsV2(params).promise();
            return data.Contents.length > 0 || data.CommonPrefixes.length > 0;

        }

        try {
            const folderPath = `${folder}${subFolder}`;
            const folderExists = await checkFolderExists(folderPath);

            if (!folderExists) {
                notifyError(`User / Project not exist`);
                throw new Error(`Folder ${folderPath} does not exist in S3.`);
            }

            if (projectId) {
                const file = files[0];
                const timestamp = moment().format('YYYYMMDD_HHmmss'); 
                const fileExtension = file.name.split('.').pop();
                const newFileName = `${timestamp}_${projectId}_BUDGET.${fileExtension}`;
               
                const key = `${folder}${subFolder}${newFileName}`;
                const params = {
                    Bucket: S3_BUCKET,
                    Key: key,
                    Body: file,
                };
    
                await s3.putObject(params).promise();
                notifySuccess("File/s uploaded successfully");
            } 
            else {
                await Promise.all(files.map( async file => {
                    const timestamp = moment().format('YYYYMMDD_HHmmss');
                    const fileExtension = file.name.split('.').pop();
                    const baseName = file.name.replace(`.${fileExtension}`, ''); 
                    const newFileName = `${timestamp}_${baseName}.${fileExtension}`;

                    const key = `${folder}${subFolder}${newFileName}`;
                    const params = {
                        Bucket: S3_BUCKET,
                        Key: key,
                        Body: file,
                    };
                    return s3.putObject(params).promise();
                }));
                notifySuccess("File/s uploaded successfully");
            }
        } catch (error) {
            notifyError("Error uploading files");
            console.error('Error uploading files:', error);
        } finally {
            setUploading(false);
            onClose();
        }
    };

    const ModalHeader = (
        <Header>
        <CloseButton onClick={onClose}>&times;</CloseButton>
          <div>
            <Heading>Upload Files</Heading>
            { !projectId ? (
            <SubHeading>
                <a href={s3FilePath} target="_blank" rel="noopener noreferrer">
                    Download Projects Template
                </a>
            </SubHeading>
            ) : null }
          </div>
        </Header>
    );

    return (
        <Conatiner>
        <Modal
            open={open}
            header={ModalHeader}
            onClose={onClose}
            hideCloseIcon
            footer={
                <>
                <div style={containerStyle}>
                    <button onClick={uploadFiles} disabled={uploading} style={buttonStyle}>
                    {uploading ? 'Uploading...' : 'Upload'}
                    </button>
                </div>
                </>
            }
         
        >
            <div
                {...getRootProps()}
                style={{ 
                    ...dropzoneStyles,
                    backgroundColor: isDragActive ? '#e3e3e3' : '#fafafa',
                }}
            >
                <input {...getInputProps()} 
                 multiple={!singleFile}
                />
                {
                    isDragActive ?
                    <p>Drop the {singleFile ? 'file' : 'files'} here...</p> :
                    <p>Drag & drop {singleFile ? 'a file' : 'files'} here, or click to select {singleFile ? 'a file' : 'files'}</p>
                }
            </div>
            <p style={acceptedFormatsText}>Accepted formats: .pdf, .csv, .xlsx</p>

            {files.length > 0 && (
                <div>
                    <h4>Files to be uploaded:</h4>
                    <div style={fileListContainerStyle}>
                        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                            {files.map((file, index) => (
                            <li key={index} style={fileListItemStyle}>
                                <span>{file.name}</span> 
                                <button onClick={() => removeFile(file)} style={removeButtonStyle}>
                                &times; 
                                </button>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </Modal>
        </Conatiner>
    );
};