import { gql } from "@apollo/client";

export const CREATE_INSPECTION = gql`
  mutation putInspection($input: InspInput) {
    PutInsp(input: $input) {
      message
      success
      result
    }
  }
`;
