import styled from 'styled-components';

export const StyledCustomToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: unset;
  border: none;
  padding: 0px 0px;
  
  .toolbar-left,
  .toolbar-center,
  .toolbar-right {
    display: flex;
    align-items: center;
  }
  
  .toolbar-center-right {
    display: flex;
    gap: 16px;
  }
`;