import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const CardsContainer = styled.div`
    display:flex;
    margin: 10px 40px;
    padding: 24px;
    gap: 16px;
    background-color:${Color.white};

    img{
        margin: 7px;
        height: 46px;
    }
`;