import React from "react";
// import ClipLoader from "react-spinners/ClipLoader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "../Button/Button";
import {
  HeaderSection,
  Cards,
  PageContainer,
  Type,
  MainContainer,
  NoProjects,
  LoaderContainer,
} from "./Projects.styles";
import { ActionCard } from "../ActionCard/ActionCard";
import IconHome from "../Icons/IconHome";
import { Color } from "../../styles/Colors";
import { projectsSelector, getProjects } from "../../redux";
import { useReadOnlyUser } from "../../hooks/useReadOnlyUser";
import { toast } from "react-toastify";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";
import { formatAddress } from "../../utils";
import NoProjectsIcon from "../../assets/images/projects/NoProjectsFound.png";
import BeatLoader from "react-spinners/BeatLoader";
import { FileUpload } from "../FileUpload/FileUpload";

export const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const { projects = [] } = useSelector(projectsSelector);
  const { companyId } = useParams();
  const isReadonlyUser = useReadOnlyUser();
  const { state } = useLocation();
  const { searchQuery = '' } = state || {};
  const [filteredProjects, setFilteredProjects] = React.useState([]);
  const [popUpOpen, setPopUpOpen] = React.useState(false);

  const totalProjects =
    Array.isArray(projects) && projects.length > 0 ? projects.length : 0;

  const navigateToCreateProjects = () => {
    navigate(`/home/create-project/${companyId}`);
  };
  const openPopUp = () => {
    setPopUpOpen(true);
  };

  const closePopUp = () => {
    setPopUpOpen(false);
  };

  const handleActionCard = ({ projectId }) => {
    navigate(`/home/project-details/${companyId}/${projectId}`);
  };

  const displayedProjects = filteredProjects.slice(0, 100);
  
  React.useEffect(() => {
    setIsLoading(true);
    if (companyId) {
      dispatch(getProjects({ companyId })).then(({ payload }) => {
        const { projects } = payload || {};
        setIsLoading(false);
        if (!projects) {
          notifyError("Not able to get projects");
        } else {
          setFilteredProjects(projects);
        }
      });
    }
  }, [companyId]);
  
  React.useEffect(() => {
    const filtered = projects.filter((project) => {
      const { pName, addr, city, st, zip } = project;
      const address = `${addr || ''} ${city || ''} ${st || ''} ${zip || ''}`;
      return (
        pName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        address.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredProjects(filtered); 
  }, [searchQuery, projects]);;

 
  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  return (
    <PageContainer>
      <MainContainer>
        <StyledToast />
        <HeaderSection>
          <Type>Projects</Type>
          <p>Displaying {filteredProjects.length} of {totalProjects}</p>
          <div className="button-group">
            <Button onClick={openPopUp}>
              Projects Upload
            </Button>
              {popUpOpen && <FileUpload open={popUpOpen} onClose={closePopUp} singleFile={false} />}
            <Button
              hasStartIcon
              type="primary"
              onClick={navigateToCreateProjects}
              disabled={isReadonlyUser}
            >
              Create New Projects
            </Button>
          </div>
        </HeaderSection>
        <Cards>
          {isLoading && (
            <LoaderContainer>
              <BeatLoader
                color={Color.darkBlue}
                loading
                margin={4}
                size={15}
                aria-label="Loading Beats"
                data-testid="loader"
              />
            </LoaderContainer>
          )}
          {!isLoading &&
            (totalProjects > 0 ? (
              displayedProjects.map((item, index) => {
                const {
                  city,
                  pName: projectName,
                  st: stateCode,
                  addr: stAddress,
                  pId: projectId,
                  zip,
                  pLoanId: projectLoanIDNumber,
                } = item || {};
                const address = formatAddress(
                  {
                    stAddress: stAddress || "",
                    city: city || "",
                    stateCode: stateCode ? stateCode.toUpperCase() : "",
                    zip: zip || "",
                  },
                  true
                );
                return (
                  <ActionCard
                    srText={""}
                    href={"#"}
                    isLoading={false}
                    media={<IconHome color={Color.secondary} />}
                    mediaPosition={"start"}
                    onClick={() => {
                      handleActionCard({ projectId });
                    }}
                    showArrow={true}
                    projectId={projectLoanIDNumber ? projectLoanIDNumber : " "}
                    title={projectName}
                    key={index}
                    index={index}
                  >
                    {address}
                  </ActionCard>
                );
              })
            ) : (
              <NoProjects>
                <img src={NoProjectsIcon} alt="no-projects-found" />
                <div>No projects found</div>
              </NoProjects>
            ))}
        </Cards>
      </MainContainer>
    </PageContainer>
  );
};

export default Projects;
