import { setBudgetItems } from "../set/setBudgetItems";
import { graphQLFetch } from "../../../utils";
import { GET_BUDGET_ITEMS } from "../../queries";

export const getBudgetItems =
  ({ pId }, callback) =>
  async (dispatch, getState) => {
    const data = await graphQLFetch(
      GET_BUDGET_ITEMS,
      { pId },
      "query",
      getState
    );
    const { GetBudgetItems } = data || {};
    callback(GetBudgetItems);
    return dispatch(setBudgetItems(GetBudgetItems || []));
  };
