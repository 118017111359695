import { graphQLFetch } from "../../../utils";
import { UPDATE_PROJECT } from "../../queries";
import { updateProject } from "../../reducers/updateProject";

export const setUpdateProject =
  ({ items }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      input: items,
    };
    const data = await graphQLFetch(
      UPDATE_PROJECT,
      variables,
      "mutation",
      getState
    );
    const { UpdateProject } = data || {};
    callback(UpdateProject);

    return dispatch(updateProject(UpdateProject));
  };
