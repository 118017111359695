import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const Container = styled.div`
  overflow: auto;
  min-height: 100vh;
  background-color: ${Color.primary}bf;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
  padding-left: 95px;
  display: flex;
  align-items: center;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  width: 720px;
  height:580px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 36px 24px 13px 24px;
  background-color: ${Color.white};
  border-radius: 16px;
  color: #000;
`;

export const MainContent = styled.div`
  display: flex;
  width: 100%;
  width: 80%;
  gap: 24px;
`;

export const CustomForm = styled.div`
  width: 60%;
`;

export const CustomHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  gap: 16px;
  align-items: center;
  p {
    max-width: 60%;
    font-weight: 400;
    font-size: 16px;
    color: #666;
    text-align: center;
  }
`;

export const LogoBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 4px;

  img {
    width: 160px;
    height: 85px;
  }
`;

export const UserText = styled.div`
  font-size: 24px;
`;

export const UserFields = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;

  [data-testid="input-code"] {
    img {
      width: 22px;
      height: 12px;
    }
  }
`;

export const UserBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const PasswordInformation = styled.div`
  border-left: 1px solid ${Color.gray};
  height: fit-content;
  padding-left: 24px;
  width:40%;
  p {
    font-weight: 600;
    margin-bottom:8px;
  }

  ul {
    li {
      list-style: none;
      font-size: 14px;
      color: #686b6d;
      line-height: 24px;
    }
  }
`;

export const SucessInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top:20px;
  img {
    height: 44px;
    width: 44px;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    color: ${Color.darkGray};
    margin-bottom: 8px;
    width: 70%;
  }
`;
