import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const SidebarConatiner = styled.div<{ buttonIndex: number }>`
  display: flex;
  flex-direction: column;

  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.18);
  position: sticky;
  top: 0;
  z-index: 1;
  height: 100%;
  a {
    text-decoration: none;
  }
  a:nth-child(${({ buttonIndex }) => buttonIndex + 1}) {
    div {
      background: #f2f2f2;
      border-right: 4px solid ${Color.primary};
    }
  }
`;

export const SidebarButton = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: #000;
  padding: 26px 0px;
  border-bottom: 1px solid #dfdfdf;
`;
