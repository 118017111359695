import { gql } from "@apollo/client";

export const CREATE_BUDGET_ITEMS = gql`
  mutation CreateBudgetItems($input: [BdgtLnItmInput]) {
    CreateBudgetItems(input: $input) {
      success
      message
    }
  }
`;
