import React from "react";
import { Container } from "./Empty.styles";

const EmptyTable = ({ tableName }) => {
  return (
    <Container>
      <p>No {tableName} Found</p>
    </Container>
  );
};

export default EmptyTable;
