import styled from "styled-components";

export const StyledSkeleton = styled.div`
  div {
    display: block;
    clear: both;
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderAnimationComposited;
      animation-timing-function: linear;
      min-width: 468px;
      background-size: 936px 104px;
      content: "";
    }
  }

  @keyframes placeHolderAnimationComposited {
    0% {
      transform: translateX(-468px);
    }
    100% {
      transform: translateX(468px);
    }
  }
`;
