import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { Button } from "../Button/Button";
import { Color } from "../../styles/Colors";

export const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BudgetMetaData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .btn-primary {
    margin-left: auto;
  }
`;

export const BudgetInfo = styled.div`
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: ${Color.darkGray};
  display: flex;
  gap: 6px;
`;

export const CustomDialogFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const FormGrid = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  :first-child {
    flex: 100% !important;
  }
  .field.col {
    flex: 50%;
    margin-bottom: 10px;
  }
`;

export const BudgetItemDialog = styled(Dialog)`
  padding: 16px;
  padding-right: 0px;
  .p-dialog-header,
  .p-dialog-footer {
    padding: 16px;
  }

  .p-dialog-content {
    overflow-y: unset;
    padding: 16px;
  }
`;
export const AddItemButton = styled(Button)`
  margin-left: auto;
`;

export const ActionButton = styled.div`
  display: flex;
  padding: 2px 0px;
  gap: 16px;

  button {
    border: none !important;
    padding: 0;

    &:focus {
      border: none !important;
      background-color: unset !important;
    }
  }
`;

export const TableConatiner = styled.div`
  th {
    background-color: #ebecee;
  }
`;
