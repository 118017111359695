import React, { useState, useEffect } from "react";
import {
  CustomLabel,
  ErrorMessage,
  InputFieldContainer,
  CloseIcon,
} from "./DatePicker.styles";
import { extractDate } from "../../utils";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

const Calendar: React.FC<{
  name: string;
  value?: Date;
  onChange: any;
  onBlur: any;
  error?: { status: boolean; message: string };
  placement?: string;
  required?: boolean;
}> = ({
  name,
  value,
  onChange = () => {},
  onBlur = () => {},
  error = { status: true, message: "", size: "12px" },
  placement = "auto",
  required,
}) => {
  const [startDate, setStartDate] = useState(null);
  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, []);

  const onChangeHandler = (e) => {
    const modifyDate = extractDate(`${e !== null ? e : ""}`);
    onChange(e instanceof Date && isNaN(e.getTime()) ? "Invalid" : modifyDate);
    setStartDate(e);
  };
  const onCloseHandler = (event) => {
    event.preventDefault();
    setStartDate(null);
    onChange("");
  };

  return (
    <InputFieldContainer error={error.status}>
      {name && (
        <CustomLabel className={required && "required"}>{name}</CustomLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          onChange={(date) => onChangeHandler(date)}
          value={startDate}
          renderInput={(params) => <TextField {...params} onBlur={onBlur} />}
          autoFocus={true}
        />
        {startDate && <CloseIcon onClick={onCloseHandler}>&times;</CloseIcon>}
      </LocalizationProvider>
      {error?.message && (
        <ErrorMessage errorMessageSize={error?.size}>
          {error.message}
        </ErrorMessage>
      )}
    </InputFieldContainer>
  );
};

export default Calendar;
