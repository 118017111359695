import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const RootContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  > div {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const Heading = styled.div`
  padding: 12px 16px 12px 16px;
  border-bottom: 1px solid ${Color.gray};
`;

export const ContactContainer = styled.div`
  display: flex;
  margin: 20px;
  border: 1px solid ${Color.gray};
  border-radius: 5px;
  gap: 40px;
  background-color: ${Color.white};
  height: 100%;
`;

export const FormContent = styled.div`
  border-right: 1px solid ${Color.gray};
  padding-right: 40px;
  > div {
    padding: 4px 0;
  }
  h3 {
    padding: 16px 0px;
    font-size: 24px;
  }
`;

export const ContactSection = styled.div`
  flex-grow: 1;
  background-color: #f5f8ff;
`;

export const ParentContainer = styled.div`
  background: #f5f8ff;
  padding: 10px;
  padding-bottom: 50px;
`;

export const ConnectContent = styled.div`
  padding: 24px 0px;
  font-size: 24px;
  > div {
    padding: 60px 4px;
  }
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 36px;
    width: 80%;
  }
  img {
    padding-right: 17px;
  }
`;
export const ErrorMessage = styled.p`
  color: ${Color.error} !important;
  font-size: 14px;
  pointer-events: none;
  padding-top: 4px;
  margin-bottom: 10px;
  position: absolute;
`;

export const ContactImageContainer = styled.div`
  height: 625px;
  filter: grayscale(1.5);
  img {
    height: 625px;
  }
`;

export const HourDisplay = styled.div`
  background: #f5f8ff;
  padding: 16px;
  height: 82px;
  p {
    line-height: 19.2px;
    font-weight: 400;
  }
`;

export const ErrorBox = styled.div`
  height: 28px;
  position: relative;
  padding: 0 !important;
`;
