import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";

import { reducers } from "./reducers";

let store;

function initStore(initialState) {
  return createStore(reducers, initialState, applyMiddleware(thunk));
}

export const initializeStore = (preloadedState: any) => {
  let _store = store ?? initStore(preloadedState);

  if (preloadedState && store) {
    _store = initStore({
      ...preloadedState,
    });
    store = undefined;
  }

  if (!store) store = _store;

  return _store;
};

export const useStore = (initialState: any) => {
  const store = initializeStore(initialState);
  return store;
};

export default store;
