import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const StyledTextarea = styled.div`
  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 31.8px;
    color: #686b6d;
    text-align: left;
  }
  textarea {
    color: ${Color.darkGray};
    font-size: 16px;
    resize: none;
    outline: none;
    padding: 12px;
    font-family: Arial;
    border-radius: 4px;
  }

  display: flex;
  flex-direction: column;
`;

export const LabelIconConatiner = styled.div`
display: flex;
gap: 8px;
align-items: center;
`;


export const LabelIcon = styled.img`
height: 20px;
width: 20px;
`;

export const CustomLabel = styled.label`
  &.required:after {
    content: " *";
  }
`;
