import React from "react";
import { IconChevronRight } from "../Icons/IconChevronRight";
import { Skeleton } from "../Skeleton/Skeleton";
// import { getInteractiveCardProps } from '../Card/utils';
import { StyledActionCard, Type, License } from "./ActionCard.styles";
import { ActionCardContentProps, ActionCardProps } from "../../types";

const ActionCardContent: React.FC<ActionCardContentProps> = ({
  children,
  media,
  showArrow,
  projectId,
  title,
  titleAs,
}) => {
  return (
    <React.Fragment>
      {media && <div className="action-card-media">{media}</div>}
      <div className="action-card-body">
        <License>{projectId}</License>
        {title && (
          <Type
            as={titleAs}
            className="action-card-title"
            styleType="body300"
            weight="bold"
          >
            {title}
          </Type>
        )}
        <span className="action-card-content">{children}</span>
      </div>
      {showArrow && (
        <div className="action-card-arrow">
          <IconChevronRight size={3} />
        </div>
      )}
    </React.Fragment>
  );
};

const loaderLayout = {
  media: [{ width: "40px", height: "40px" }],
  body: [
    { width: "86px", height: "24px" },
    { width: "272px", height: "20px" },
  ],
};

export const ActionCard: React.FC<ActionCardProps> = ({
  children,
  href,
  isLoading,
  media,
  mediaPosition = "start",
  onClick,
  showArrow = false,
  srText,
  title,
  titleAs = "h3",
  projectId,
  index,
  ...props
}) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <StyledActionCard
      aria-busy={isLoading}
      aria-label={projectId}
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDown}
      //   {...getInteractiveCardProps({ href, onClick })}
      {...{ href, mediaPosition, onClick, title, ...props }}
    >
      {isLoading ? (
        <div
          className="action-card-skeleton"
          data-testid="action-card-skeleton"
        >
          <Skeleton
            className="action-card-skeleton-media"
            layout={loaderLayout.media}
          />
          <Skeleton
            className="action-card-skeleton-body"
            layout={loaderLayout.body}
            spacing="4px"
          />
        </div>
      ) : (
        <ActionCardContent
          {...{ media, title, titleAs, projectId, children, showArrow }}
        />
      )}
    </StyledActionCard>
  );
};

export default ActionCard;
