import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  HeaderSection,
  MainContainer,
  Type,
  DeleteButton,
  Container,
  BackButton,
} from "./ProjectPreview.styles";

import BackIcon from "../../assets/images/Common/back-icon.png";
import DeleteIcon from "../../assets/images/Common/deleteIcon.png";
import { useReadOnlyUser } from "../../hooks/useReadOnlyUser";
import { projectByIdSelector, deleteProject } from "../../redux";

import Tabs from "../Tabs/Tabs";
import { PageContainer } from "../Projects/Projects.styles";
import DeleteProject from "../DeleteProject/DeleteProject";
import ProjectInformation from "../ProjectInformation/ProjectInformation";
import BudgetList from "../BudgetList/BudgetList";
import UnitInformation from "../UnitInformation/UnitInformation";
import InspectionData from "../InspectionData/Inspection";
import { toast } from "react-toastify";
// import VerticalIqLogo from "../../assets/images/Common/Vertical_iq_logo.png";
// import BudgetRiskDashboard from "../../assets/reports/Budget Risk Dashboard.pdf";
// import BudgetMetrics from "../../assets/reports/Construction Category Budget Metrics.pdf";
// import BudgetBreakdown from "../../assets/reports/Budget Breakdown.pdf";
import Button from "../Button/Button";

// import jsPDF, { loadFile } from "jspdf";
// import { PDFDocument, PDFEmbeddedPage, PDFPage } from "pdf-lib";
import { getTableauToken, exportTableauViewToPdf } from "../../redux/actions/get/getTableauToken";
import ClipLoader from "react-spinners/ClipLoader";

const ProjectPreview: React.FC = () => {
  const [isDelete, setDelete] = React.useState(false);
  const [isExporting, setIsExporting] = React.useState(false);
  const dispatch = useDispatch();
  const { currentProject } = useSelector(projectByIdSelector);
  const { pName } = currentProject || {};
  const [isLoading, setIsLoading] = React.useState(false);
  const isReadonlyUser = useReadOnlyUser();

  const { companyId, projectId } = useParams();
  const navigate = useNavigate();


  const onDeleteHandler = () => {
    setIsLoading(true);
    dispatch(
      deleteProject({ projectId }, (res) => {
        setDelete(false);
        setIsLoading(false);
        if (res?.success) {
          notifySuccess(res?.message, () => {
            navigate(-1);
          });
        } else {
          notifyError(res?.message || "Not able to delete project");
        }
      })
    );
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message, onclose) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: onclose,
    });
  };

  const handleExport = async () => {
    setIsExporting(true);
    await dispatch(exportTableauViewToPdf(projectId));
    setIsExporting(false);
  };

  return (
    <PageContainer>
      <MainContainer>
        {isDelete && (
          <DeleteProject
            setShow={() => setDelete(false)}
            projectId={projectId}
            onDelete={onDeleteHandler}
            isLoading={isLoading}
          />
        )}
        <HeaderSection>
          <BackButton
            onClick={() => navigate(`/home/${companyId}`)}
            type="button"
          >
            <img src={BackIcon} alt="level" />
          </BackButton>
          {projectId && <Type>{pName}</Type>}
          <DeleteButton
            onClick={() => {
              setDelete(true);
            }}
            disabled={isReadonlyUser}
          >
            <img src={DeleteIcon} alt="menuButton" title="Delete Project" />
          </DeleteButton>
        </HeaderSection>
        <Container>
          <Tabs
            tabData={[
              {
                label: "Project Information",
                value: "1",
                content: (
                  <ProjectInformation
                    projectId={projectId}
                    companyId={companyId ?? ""}
                  />
                ),
              },
              {
                label: "Budgets",
                value: "2",
                content: (
                  <BudgetList pId={projectId} pName={pName} />
                ),
              },
              {
                label: "Unit Information",
                value: "3",
                content: <UnitInformation pId={projectId} pName={pName} />,
              },
              {
                label: "Inspection Data",
                value: "4",
                content: <InspectionData pId={projectId ?? ""} pName={pName} />,
              },
            ]}
            actionButton = {isExporting ? (
              <ClipLoader color="#36D7B7" loading={isExporting} size={35} />
            ) : (
              <Button
                variant="primary"
                size="small"
                onClick={handleExport}
                disabled={isExporting}
              >
                Export
              </Button>
            )}
          />
        </Container>
      </MainContainer>
    </PageContainer>
  );
};

export default ProjectPreview;
