import React from "react";
import { StyledTextarea, CustomLabel, LabelIconConatiner, LabelIcon } from "./TextAreaField.styles";

interface TextAreaFieldProps {
  name: string;
  showLabel?: boolean;
  onChange?: () => {};
  onBlur?: () => {};
  value?: string;
  required?: boolean;
  dataType?: string;
  maxLength?: number;
  showLabelIcon?: boolean;
  labelIcon?: any;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  name,
  showLabel = false,
  onChange = () => {},
  onBlur,
  value,
  required,
  dataType,
  maxLength,
  showLabelIcon,
  labelIcon,
}) => {
  const [inputData, setInputData] = React.useState(value);
  const onChangeHandler = (event) => {
    const inputValue = event.target.value;
    if (
      dataType === "alphanumericWithSpecialChars" &&
      /~/g.test(inputValue) &&
      inputValue !== ""
    ) {
      return;
    }
    if (maxLength && maxLength < inputValue?.length) {
      return;
    }
    setInputData(inputValue);
    onChange(event, inputValue);
  };

  return (
    <StyledTextarea>
      {showLabel && (
        <LabelIconConatiner>
          {showLabelIcon && labelIcon && <LabelIcon src={labelIcon}/>}
          <CustomLabel className={required && "required"} htmlFor={name}>
            {name}
          </CustomLabel>
        </LabelIconConatiner>
      )}
      <textarea
        id={name}
        name={name}
        value={inputData}
        rows={4}
        cols={50}
        onChange={onChangeHandler}
        onBlur={onBlur}
      ></textarea>
    </StyledTextarea>
  );
};

export default TextAreaField;
