import styled from "styled-components";
import { Color } from "../../styles/Colors";
import { CloseButton } from "../Modal/Modal.styles";

export const InputFieldContainer = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  fieldset {
    border: 1px solid
      ${({ error }) => (error ? Color.outlineVarient : Color.error)};
  }
  input {
    padding: 8px 12px !important;
    .Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${Color.error} !important;
    }
  }
`;

export const CustomLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-bottom: 8px;
  color: #686b6d;
  text-align: left;

  &.required:after {
    content: " *";
  }
`;

export const ErrorMessage = styled.p<{ errorMessageSize: string }>`
  color: ${Color.error} !important;
  font-size: ${({ errorMessageSize }) =>
    errorMessageSize ? errorMessageSize : "14px"} !important;
  pointer-events: none;
  padding-left: 8px;
  padding-top: 4px;
`;

export const CloseIcon = styled(CloseButton).attrs({
  tabIndex: -1,
})`
  right: 40px;
  position: absolute;
  top: 31px;
  font-size: 22px;
`;
