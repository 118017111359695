import styled from "styled-components";
import { Color } from "../../../../styles/Colors";

export const AnalyicsConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeadingText = styled.p`
  font-size: 16px;
  color: ${Color.darkGray};
  font-weight: 400;
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const CookiesMessage = styled.div``;
