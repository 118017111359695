export const hasAnyErrorStatusFalse = (error) => {
  for (const key in error) {
    if (Object.hasOwn(error, key)) {
      if (!error[key].status) {
        return true;
      }
    }
  }
  return false;
};

export const hasAnyEmptyFiled = (inputFields) => {
  for (const key in inputFields) {
    if (Object.hasOwn(inputFields, key)) {
      if (!inputFields[key] && key !== "Company ID*") {
        return true;
      }
    }
  }
  return false;
};

export const checkAnyStatusFalseInArray = (array) => {
  for (const obj of array) {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        typeof obj[key] === "object" &&
        "status" in obj[key]
      ) {
        if (!obj[key].status) {
          return true;
        }
      }
    }
  }
  return false;
};
