import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;

  p {
    font-size: 20px;

    font-weight: 600;
  }
`;
