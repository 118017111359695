import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import InputField from "../InputField/InputField";
import Dropdown from "../Dropdown";
import Button from "../Button";
import NavBar from "../Navbar/Navbar";
import {
  Container,
  Form,
  UserText,
  UserFields,
  ContentBox,
  UserBtn,
  TooltipInformation,
  ErrorMessage,
  BackgroundImage,
  WapperConatiner,
  LoaderContainer,
} from "./CreateNewUser.styles";
import {
  hasAnyEmptyFiled,
  hasAnyErrorStatusFalse,
  isValidEmail,
  isValidPassword,
} from "../../utils";
import { trimDescFromType } from "../../utils/common/validation";
import { signUp } from "../../utils/cognito-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllCompanies,
  selectAllCompanies,
  userInfoSelector,
} from "../../redux";
import { StyledToast } from "./CreateNewUser.styles";
import AutoComplete from "../Autocomplete/Autocomplete";
import { Color } from "../../styles/Colors";
import { genrateUUDI } from "../../utils/common/utils";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useNavigate } from "react-router-dom";

const input = {
  "Company Type*": null,
  "Company Name*": null,
  "User Email*": "",
  "User Type*": "",
  "User Name*": "",
  "Password*": "",
  "Confirm Password*": "",
};

const defaultInputError = {
  "Company ID*": { status: true, message: "", size: "12px" },
  "Company Type*": { status: true, message: "", size: "12px" },
  "Company Name*": { status: true, message: "", size: "12px" },
  "User Email*": { status: true, message: "", size: "12px" },
  "User Type*": { status: true, message: "", size: "12px" },
  "User Name*": { status: true, message: "", size: "12px" },
  "Password*": { status: true, message: "", size: "12px" },
  "Confirm Password*": { status: true, message: "", size: "12px" },
};

export const CreateNewUser = () => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = React.useState({ ...input });
  const [errorMessage, setErrorMessage] = React.useState(defaultInputError);
  const [isError, setIsError] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCompanyTypeExist, setIsCompanyTypeExist] = React.useState(false);
  const userInfo = useSelector(userInfoSelector);
  const navigate = useNavigate();
  const getAllCompaniesData = useSelector(selectAllCompanies) || {};
  const {
    getAllCompaniesName: allCompanies = [],
    getAllCompanyType = [],
    getAllUserType = [],
  } = getAllCompaniesData || {};

  const companyTypeOptions =
    getAllCompanyType?.map((companyType) => ({
      companyTypeId: companyType?.COMPANY_TYPE_ID,
      label: `${companyType?.COMPANY_TYPE} ${companyType?.SIZE_DESCRIPTION}`,
    })) || [];

  const userTypeOptions =
    getAllUserType
      ?.filter((userType) => userType?.USER_ROLE?.toLowerCase() !== "admin")
      ?.map((userType) => ({
        userRoleId: userType?.USER_ROLE_UUID,
        label: userType?.USER_ROLE,
      })) || [];

  const { signInUserSession } = userInfo || {};

  const { idToken } = signInUserSession || {};
  const { payload } = idToken || {};
  const customPayload = payload || {};
  const customUserType = customPayload["custom:userType"]
    ? customPayload["custom:userType"].toLowerCase()
    : "";

  useEffect(() => {
    if (customUserType !== "admin") {
      navigate(-1);
    } else {
      setIsDataLoading(true);
      dispatch(
        getAllCompanies(() => {
          setIsDataLoading(false);
        })
      );
    }
  }, []);

  const onChangeHandler = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
    setIsError(false);
  };

  const clearInputValues = () => {
    setInputValues({ ...input });
    setErrorMessage(defaultInputError);
    setIsError(false);
    setIsCompanyTypeExist(false);
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    let companyId = inputValues["Company ID*"]?.toLowerCase();
    const companyTypeObj = inputValues["Company Type*"];
    const companyName = inputValues["Company Name*"];
    const userEmail = inputValues["User Email*"];
    const userTypeObj = inputValues["User Type*"];
    const userName = inputValues["User Name*"];
    const password = inputValues["Password*"];
    const userType = userTypeObj?.label
      ? userTypeObj?.label?.replace(/ /g, "")
      : "";

    if (
      !hasAnyErrorStatusFalse(errorMessage) &&
      !hasAnyEmptyFiled(inputValues)
    ) {
      const { label = "", companyTypeId } = companyTypeObj || {};
      const { companyType, companyDesc } = trimDescFromType(label);

      if (companyId === "" || companyId === null) {
        companyId = genrateUUDI(
          allCompanies.map((company) => company?.COMPANY_UUID)
        );
      }

      setIsError(false);
      const response = await signUp({
        companyId,
        companyType,
        companyName,
        userEmail,
        userType,
        userName,
        password,
        companyDesc,
        companyTypeId,
      });
      if (response?.response?.message) {
        notifyError(
          response?.response?.message?.includes("User already exists")
            ? "An account with the given email already exists."
            : "Not able to create user"
        );
      } else {
        notifySuccess("User Created Successfully");
        clearInputValues();
      }
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const companyChangeHandler = (event, name) => {
    const value = event?.value;

    setInputValues({
      ...inputValues,
      [name]: event,
    });

    if (value === "") {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: `Select ${name}`,
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const handlerDropdownOnBlur = (event, name) => {
    const value = inputValues[name];
    if (value === "") {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: `Select ${name}`,
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const userTypeChangeHandler = (event) => {
    const name = "User Type*";
    const value = event?.value;
    setInputValues({
      ...inputValues,
      "User Type*": event,
    });

    if (value === "" || value === null) {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: `Select ${name}`,
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const onBlurHandler = (event) => {
    const name = event?.target?.name;
    const value = inputValues[name];

    if (name === "Password*") {
      const { status } = isValidPassword(value);
      const checkPasswordAndConfirmPassword =
        inputValues["Confirm Password*"] === "" ||
        inputValues[name] === inputValues["Confirm Password*"];
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status,
          message: status ? "" : "Password Requirements not met",
        },
        "Confirm Password*": {
          ...errorMessage["Confirm Password*"],
          status: checkPasswordAndConfirmPassword,
          message: checkPasswordAndConfirmPassword
            ? ""
            : "Password and confirm password do not match",
        },
      });
    } else if (name === "Confirm Password*") {
      setErrorMessage({
        ...errorMessage,
        "Password*": {
          ...errorMessage["Password*"],
          status: errorMessage["Password*"].status,
          message: errorMessage["Password*"].message,
        },
        [name]: {
          ...errorMessage[name],
          status:
            inputValues[name] !== "" &&
            inputValues["Password*"] === inputValues["Confirm Password*"],
          message:
            inputValues[name] === ""
              ? "Password Requirements not met"
              : `${
                  inputValues["Password*"] === inputValues["Confirm Password*"]
                    ? ""
                    : "Password and confirm password do not match"
                }`,
        },
      });
    } else if (name === "User Email*") {
      const { status } = isValidEmail(value);
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status,
          message: status ? "" : "Please enter a valid email address",
        },
      });
    } else if (value === "") {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: "Required field",
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const comboBoxChangeHandler = (value, name) => {
    setErrorMessage({
      ...errorMessage,
      [name]: { ...errorMessage[name], status: true, message: "" },
    });
    // setInputValues({
    //   ...inputValues,
    //   [name]: value === "" ? null : value,
    // });
    const currentCompany = allCompanies?.find(
      (company) => company?.COMPANY_NAME === value
    );

    const companyType = companyTypeOptions?.find(
      (item) => item?.companyTypeId === currentCompany?.COMPANY_TYPE_ID
    );
    if (companyType) {
      setIsCompanyTypeExist(true);
    } else {
      setIsCompanyTypeExist(false);
    }
    setInputValues({
      ...inputValues,
      [name]: value === "" ? null : value,
      "Company ID*": currentCompany?.COMPANY_UUID || "",
      "Company Type*": companyType || "",
    });
  };

  const comboBoxBlurHandler = () => {
    const name = "Company Name*";
    const value = inputValues[name];
    if (value === "" || value === null) {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: `Select ${name}`,
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const Tooltipcontent = (
    <TooltipInformation>
      <ul>
        <li>Length: 7-16 characters</li>
        <li>At least 1 number</li>
        <li>At least 1 special character</li>
        <li>At least 1 uppercase letter</li>
        <li>At least 1 lowercase letter</li>
      </ul>
    </TooltipInformation>
  );

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <WapperConatiner>
      <NavBar />
      <StyledToast />
      <BackgroundImage />
      {customUserType === "admin" && (
        <Container>
          {isDataLoading ? (
            <LoaderContainer>
              <PropagateLoader color={Color.white} size={20} />
            </LoaderContainer>
          ) : (
            <ContentBox>
              <Form>
                <UserText>Create New User</UserText>
                <UserFields autoComplete="false">
                  <AutoComplete
                    value={inputValues["Company Name*"]}
                    name="Company Name*"
                    options={
                      allCompanies?.map((company) => company.COMPANY_NAME) || []
                    }
                    error={errorMessage["Company Name*"]}
                    loading={false}
                    onChange={(value) =>
                      comboBoxChangeHandler(value, "Company Name*")
                    }
                    onBlur={() => comboBoxBlurHandler()}
                  />
                  <Dropdown
                    showLabel
                    value={inputValues["Company Type*"]}
                    name="Company Type*"
                    onChange={(e) => {
                      companyChangeHandler(e, "Company Type*");
                    }}
                    onBlur={handlerDropdownOnBlur}
                    options={companyTypeOptions}
                    disabled={isCompanyTypeExist}
                    errorMessage={errorMessage["Company Type*"]}
                  />
                  <InputField
                    value={inputValues["User Email*"]}
                    name={"User Email*"}
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    errorMessage={errorMessage["User Email*"]}
                    showLabel
                    maxLength={200}
                    autoComplete="off"
                  />
                  <Dropdown
                    value={inputValues["User Type*"]}
                    showLabel
                    name="User Type*"
                    defaultValue={""}
                    onChange={userTypeChangeHandler}
                    onBlur={handlerDropdownOnBlur}
                    options={userTypeOptions}
                    errorMessage={errorMessage["User Type*"]}
                  />
                  <InputField
                    value={inputValues["User Name*"]}
                    name={"User Name*"}
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    errorMessage={errorMessage["User Name*"]}
                    showLabel
                    maxLength={200}
                    autoComplete="off"
                  />
                  <InputField
                    value={inputValues["Password*"]}
                    name={"Password*"}
                    type="password"
                    showLabel
                    showTooltip
                    tooltipContent={Tooltipcontent}
                    errorMessage={errorMessage["Password*"]}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    maxLength={16}
                    autoComplete="new-password"
                    showPasswodIcon
                    regexPattern={/^[^\\]*$/}
                  />
                  <InputField
                    value={inputValues["Confirm Password*"]}
                    name={"Confirm Password*"}
                    type="password"
                    showLabel
                    errorMessage={errorMessage["Confirm Password*"]}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    maxLength={16}
                    autoComplete="new-password"
                    showPasswodIcon
                    regexPattern={/^[^\\]*$/}
                  />
                </UserFields>
                {isError && (
                  <ErrorMessage>Please fill Required* fields </ErrorMessage>
                )}
                <UserBtn>
                  <Button
                    className="btn-default"
                    type="reset"
                    onClick={clearInputValues}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn-primary"
                    type="Submit"
                    isLoading={isLoading}
                    onClick={onSubmitHandler}
                  >
                    {isLoading ? (
                      <ClipLoader
                        color={Color.white}
                        size={15}
                        cssOverride={{
                          marginLeft: "17px",
                          marginRight: "17px",
                          marginTop: "2px",
                          marginBottom: "2px",
                        }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </UserBtn>
              </Form>
            </ContentBox>
          )}
        </Container>
      )}
    </WapperConatiner>
  );
};

export default CreateNewUser;
