import { gql } from "@apollo/client";

export const DELETE_PROJECT = gql`
  mutation deleteProject($pId: ID!) {
    DeleteProject(pId: $pId) {
      message
      success
      result
    }
  }
`;
