import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import InfoLogo from "../../assets/images/Common/icon-i.svg";
import { Color } from "../../styles/Colors";

const CustomTooltip: React.FC<{
  id: string;
  content: any;
  varient?: string;
}> = ({ id, content, varient = "dark" }) => {
  return (
    <>
      <span data-tooltip-id={id} data-tooltip-variant={varient}>
        <img src={InfoLogo} alt="tooltip" />
      </span>
      <Tooltip
        id={id}
        style={{
          backgroundColor: `${Color.primary}E5`,
          color: Color.white,
          padding: "11px 16px",
        }}
      >
        {content}
      </Tooltip>
    </>
  );
};

export default CustomTooltip;
