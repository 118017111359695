import { gql } from "@apollo/client";

export const GET_INSPECTIONS = gql`
  query getProjInsp($pId: ID!) {
    GetProjInsp(pId: $pId) {
      inspDate
      inspCompleted
      inspDrawAmt
      inspId
    }
  }
`;
