import React, { useState } from "react";
import { TabContainer, TabHeader, TabButton, TabContent } from "./Tabs.styles";

export interface TabData {
  label: string;
  value: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabData: TabData[];
  onTabChange?: any;
  actionButton?: React.ReactNode;
}


const Tabs: React.FC<TabsProps> = ({ tabData, onTabChange = () => {}, actionButton }) => {
  const [activeTab, setActiveTab] = useState(tabData[0].value);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    if (onTabChange) {
      onTabChange();
    }
  };

  const getTabContent = () => {
    const newItems = [...tabData];
    const items = newItems.filter(({ value }) => activeTab === value);
    return items.map(({ value, content }) => (
      <div
        key={value}
        style={{ display: activeTab === value ? "block" : "none" }}
      >
        {content}
      </div>
    ));
  };

  return (
    <TabContainer>
      <TabHeader data-testid="tab-header">
        {tabData.map(({ label, value }) => (
          <TabButton
            key={value}
            active={activeTab === value}
            onClick={() => handleTabChange(value)}
          >
            {label}
          </TabButton>
        ))}
        {actionButton}
      </TabHeader>
      <TabContent>{getTabContent()}</TabContent>
    </TabContainer>
  );
};

export default Tabs;
