import React from "react";
import { useDispatch } from "react-redux";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { Container, MainSection } from "./Home.styles";
import Projects from "../Projects/Projects";
import { Routes, Route, useLocation } from "react-router-dom";
import CreateProject from "../CreateProject/CreateProject";
import Analytics from "../Analytics/Analytics";

import { ApolloProvider } from "@apollo/client";
import apolloClient from "../../utils/helpers/withApollo";
import { authUser } from "../../utils/helpers/AwsConfig";
import ProjectPreview from "../ProjectPreview/ProjectPreview";
import { setApolloClient, setUserInfo } from "../../redux";
import Footer from "../Footer/Footer";
import CreateNewUser from "../CreateNewUser/CreateNewUser";

export const Home = () => {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const location = useLocation();
  const isCreateUserPage = location.pathname?.includes("create-new-user");

  React.useEffect(() => {
    authUser().then((data) => {
      dispatch(setUserInfo(data));
      const { signInUserSession } = data || {};
      const { accessToken } = signInUserSession || {};
      const { jwtToken } = accessToken || {};
      if (jwtToken) {
        setIsLoggedIn(true);
        dispatch(setApolloClient(apolloClient));
      } else {
        window.location.href = "/login";
      }
    });
  }, []);

  return (
    <MainSection>
      <div>
        {!isCreateUserPage && <Navbar />}
        <Container isCreateUserPage={isCreateUserPage}>
          <div>
            {!isCreateUserPage && <Sidebar />}
            {isLoggedIn && (
              <ApolloProvider client={apolloClient}>
                <Routes>
                  <Route index={0} path="/:companyId" element={<Projects />} />
                  <Route
                    index={1}
                    path="create-project/:companyId"
                    element={<CreateProject />}
                  />
                  <Route
                    index={2}
                    path="/project-details/:companyId/:projectId"
                    element={<ProjectPreview />}
                  />
                  <Route path="analytics" element={<Analytics />} />
                  <Route path="/create-new-user" element={<CreateNewUser />} />
                </Routes>
              </ApolloProvider>
            )}
          </div>
        </Container>
        {!location.pathname?.includes("create-new-user") && <Footer />}
      </div>
    </MainSection>
  );
};

export default Home;
