import { graphQLFetch } from "../../../utils";
import { CREATE_UNIT } from "../../queries";

export const createUnit =
  ({ input, pId }, callback) =>
  async (dispatch, getState) => {
    delete input["unitId"];
    const variables = {
      input: { ...input, pId },
    };
    const data = await graphQLFetch(
      CREATE_UNIT,
      variables,
      "mutation",
      getState
    );
    const { PutUnit } = data || {};
    callback(PutUnit);
  };
