import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import {
  Homecontent,
  CardsDetail,
  WelcomeContainer,
  ParentContainer,
} from "./Welcome.style";
import Button from "../Button";
import Homecards from "../Homecards/Homecards";
import {
  batLogo,
  eocLogo,
  rpmLogo,
  marketLogo,
} from "../../assets/images/Welcome/index";
import { useNavigate } from "react-router-dom";

export const Welcome = () => {
  const navigate = useNavigate();
  return (
    <ParentContainer>
      <div>
        <Navbar />
        <Homecontent>
          <div>
            <WelcomeContainer>
              <h1>Welcome to Vertical-IQ</h1>
              <div>
                <p>
                  At Vertical-IQ we provide Risk Management tools for the
                  residential new construction industry.
                </p>
                <p>
                  Coming from the Construction Finance industry, we know what it
                  takes to analyze a new build in terms of the market dynamics,
                  budgets assessments relative to recent like and kind projects.
                </p>
                <p>
                  In addition we’ve used Machine Learning and AI to create what
                  we refer to as EOC, an estimated End of Construction, that
                  updates as you progress through the project. An assessment of
                  your project speed allows us to project this estimated
                  completion time, allowing you to understand when a project is
                  falling behind schedule, well before the end of the project
                  when that information may come as a surprise.
                </p>
                <p>
                  Contact us today to learn more about how we can help you as a
                  builder, lender, inspection company or due diligence firm to
                  get a firm grasp of project risks.
                </p>
              </div>
              <Button
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Get Started
              </Button>
            </WelcomeContainer>
            <CardsDetail>
              <Homecards
                image={batLogo}
                title="BAT - Budget Assessment Tool"
                description="View your budget compared to similar projects across a number of key categories"
              />
              <Homecards
                image={eocLogo}
                title="EOC - End of Construction"
                description="Our ML informed tool, coupled with AI, is like a mapping system that tells you how long untill you finish your journey, regularly updated by the progress of your build."
              />
              <Homecards
                image={rpmLogo}
                title="RPM - Risk Performance Monitor"
                description="Providing a top down view of the project and KPIs to monitor."
              />
              <Homecards
                image={marketLogo}
                title="Market IQ"
                description="Providing real estate market context to your proposed project. Understand the state of your local market before making that decision to build."
              />
            </CardsDetail>
          </div>
        </Homecontent>
        <Footer></Footer>
      </div>
    </ParentContainer>
  );
};

export default Welcome;
