import React from "react";
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalWrapper,
  ModalContent,
  CloseButton,
} from "./Modal.styles";
const Modal: React.FC<{
  open: boolean;
  onClose: () => void;
  header: string | React.ReactNode;
  footer: React.ReactNode;
  hideCloseIcon?: boolean;
  children: React.ReactNode;
}> = ({ open, onClose, header, children, footer, hideCloseIcon = false }) => {
  return (
    <ModalWrapper open={open}>
      <ModalContent className="modal">
        <ModalHeader className="modal-header">
          {typeof header === "string" ? <h1>{header}</h1> : header}
          {!hideCloseIcon && (
            <CloseButton aria-label="close-button" onClick={onClose}>
              &times;
            </CloseButton>
          )}
        </ModalHeader>
        <ModalBody className="modal-body">{children}</ModalBody>
        <ModalFooter className="modal-footer">{footer}</ModalFooter>
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
