import styled from "styled-components";
import Button from "../../../Button/Button";
import { Color } from "../../../../styles/Colors";

interface FormGridProps {
  isEdit: boolean;
}

export const Conatiner = styled.div`
  .modal {
    padding: 0px;
    width: 800px;
  }
  .modal-header {
    padding: 0px;
  }
  .modal-body {
    overflow-y: auto;
    padding: 24px;
    min-height: 65vh;
    max-height: 70vh;
  }

  .modal-footer {
    padding: 0px;

    z-index: 999;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  box-shadow: 0px 4px 21px 0px ${Color.primary}17;
  width: 100%;
  padding: 16px;
`;
export const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: "Lato", "Roboto";
  color: ${Color.primary};
`;

export const SubHeading = styled(Heading)`
  font-size: 16px;
  color: #686b6d;
  line-height: 19.2px;
`;

export const CustomDialogFooter = styled.div`
  box-shadow: 0px -4px 21px 0px #0000001a;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const FormGrid = styled.div<FormGridProps>`
  display: grid;
  grid-template-columns: ${({isEdit}) => (isEdit ? '4fr 6fr 3fr 30px' : '0px 10fr 3fr 30px')} ;

  gap: 16px;
  border-bottom: 1px solid ${Color.gray};
  padding-bottom: 16px;
  margin-bottom: 16px;

  > div {
    margin: 0px;
  }

  .delete-icon {
    margin-top: 25px;
    align-self: center;
  }
`;

export const AddItemButton = styled(Button)`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.span`
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;
