import React, { useEffect, useState } from "react";

import DeleteIcon from "../../../../assets/images/Common/deleteIcon.png";
import DarkAddIcon from "../../../../assets/images/Common/DarkAddIcon.png";

import {
  CustomDialogFooter,
  FormGrid,
  AddItemButton,
  Header,
  Heading,
  SubHeading,
  Conatiner,
  CloseButton,
} from "./CreateBudgetModel.styles";

import InputBox from "../../../InputField/InputField";
import Dropdown from "../../../Dropdown";
import Button from "../../../Button/Button";
import Modal from "../../../Modal/Modal";
import { checkAnyStatusFalseInArray } from "../../../../utils";

export const itemsFields = ["Category", "Budget Item Description", "Amount"];

export const CreateBudgetModel: React.FC<{
  onClose: () => void;
  product: any;
  saveProduct: (data: any) => void;
  editBudget: (data: any) => void;
  pName: string;
  btnLoading: boolean;
  categoryOptions: Array<{ label: string; value: string }>;
}> = ({
  onClose,
  product = null,
  saveProduct,
  editBudget,
  pName,
  btnLoading,
  categoryOptions,
}) => {
  const initialState = {
    Category: { value: "select", label: "Select Category" },
    "Budget Item Description": "",
    Amount: "",
  };

  const inputError = {
    Category: { status: true, message: "" },
    "Budget Item Description": { status: true, message: "" },
    Amount: { status: true, message: "" },
  };

  const [addBudgetItems, setAddBudgetItems] = useState([{ ...initialState }]);
  const [errorFields, setErrorFields] = useState([{ ...inputError }]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const { amt, bdgtCategory, bdgtDesc } = product || {};
    if (product !== null && bdgtCategory) {
      setIsEdit(true);
      const value = categoryOptions.find(
        ({ value, label }) => bdgtCategory === label || bdgtCategory === value
      );
      setAddBudgetItems([
        {
          Amount: amt,
          "Budget Item Description": bdgtDesc,
          Category: value
            ? value
            : { label: "Select Category", value: "select" },
        },
      ]);
    }
  }, []);

  const checkValidation = () => {
    const errorArr = [...errorFields];
    for (let index in addBudgetItems) {
      for (let name of itemsFields) {
        if (name !== "Category") {
          onBlurInputHandler({ target: { name } }, index);
        }
      }
    }
    setErrorFields(errorArr);
  };

  const saveProductHandler = () => {
    checkValidation();
    if (!checkAnyStatusFalseInArray(errorFields)) {
      if (product === null) {
        saveProduct(addBudgetItems);
      } else {
        editBudget(addBudgetItems);
      }
    }
  };

  const ModalFooter = (
    <CustomDialogFooter>
      <Button type="button" className="btn-default" onClick={onClose}>
        Cancel
      </Button>
      <Button
        type="button"
        className="btn-primary"
        onClick={saveProductHandler}
        isLoading={btnLoading}
      >
        Save
      </Button>
    </CustomDialogFooter>
  );

  const addItemHandler = () => {
    const newArr = [...addBudgetItems];
    const newErrorField = [...errorFields];

    newArr.push({ ...initialState });
    newErrorField.push({ ...inputError });

    setErrorFields(newErrorField);
    setAddBudgetItems(newArr);
  };

  const deleteItemHandler = (index) => {
    const newArr = [...addBudgetItems];
    const newErrorFields = [...errorFields];

    newArr.splice(index, 1);
    newErrorFields.splice(index, 1);

    setErrorFields(newErrorFields);
    setAddBudgetItems(newArr);
  };
  const resetError = () => {
    const newErrorField = [];

    for (let indx in addBudgetItems) {
      newErrorField.push({ ...inputError });
    }

    setErrorFields(newErrorField);
  };

  // Dropdown
  const onChangeCategoryHandler = (event, index) => {
    const newArr = [...addBudgetItems];
    const currentItem = newArr[index];
    currentItem["Category"] = event;
    setAddBudgetItems(newArr);
    resetError();
  };
  // input fields
  const onChangeHandler = (event, index) => {
    const newArr = [...addBudgetItems];
    const currentItem = newArr[index];
    currentItem[event.target.name] = event.target.value;
    setAddBudgetItems(newArr);
    resetError();
  };

  const onBlurInputHandler = (event, index) => {
    const errorArr = [...errorFields];
    const { name } = event.target;

    const errorCondition = !(
      !addBudgetItems[index][name] ||
      addBudgetItems[index][name] === "" ||
      addBudgetItems[index][name] === 0
    );

    errorArr[index][name] = {
      ...errorArr[index][name],
      status: errorCondition,
      message: errorCondition ? "" : "required field",
    };

    setErrorFields(errorArr);
  };

  const ModalHeader = (
    <Header>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <div>
        <Heading>{product === null ? "Create" : "Edit"} Budget Items</Heading>
        <SubHeading>{pName}</SubHeading>
      </div>
    </Header>
  );

  return (
    <Conatiner>
      <Modal header={ModalHeader} footer={ModalFooter} hideCloseIcon={true}>
        {addBudgetItems.map((item, index) => {
          return (
            <FormGrid isEdit={isEdit} key={index}>
              <div className= "field col" key={index}>
                {isEdit && (
                <Dropdown
                  showLabel
                  value={item["Category"]}
                  name={"Category"}
                  onChange={(event) => onChangeCategoryHandler(event, index)}
                  options={categoryOptions}
                  errorMessage={errorFields[index]["Category"]}
                />
                )}
              </div>
              <div className="field col">
                <InputBox
                  value={item["Budget Item Description"]}
                  name={"Budget Item Description"}
                  type="text"
                  showLabel
                  required
                  onChange={(event) => onChangeHandler(event, index)}
                  errorMessage={errorFields[index]["Budget Item Description"]}
                  maxLength={150}
                />
              </div>
              <div className="field col">
                <InputBox
                  value={item["Amount"]}
                  name={"Amount"}
                  type="number"
                  showLabel
                  isDollorIcon
                  required
                  errorMessage={errorFields[index]["Amount"]}
                  maxLength={11}
                  onChange={(event) => onChangeHandler(event, index)}
                  placeholder="$ 0.00"
                  showPlaceHolder
                />
              </div>
              {index !== 0 && (
                <div
                  className={"delete-icon"}
                  onClick={() => deleteItemHandler(index)}
                >
                  <img src={DeleteIcon} alt="deleteIcon" />
                </div>
              )}
            </FormGrid>
          );
        })}
        {product === null && (
          <AddItemButton
            type="button"
            className="btn-default"
            onClick={addItemHandler}
          >
            <img src={DarkAddIcon} alt="addIcon" />
            Add Item
          </AddItemButton>
        )}
      </Modal>
    </Conatiner>
  );
};

export default CreateBudgetModel;
