import React, { useState, useEffect } from "react";

import { UserFields } from "./UserInputFields.styles";
import InputField from "../InputField/InputField";
import Dropdown from "../Dropdown/Dropdown";
import TextAreaField from "../TextAreaField/TextAreaField";

import { PROJECT_TYPE_OPTIONS } from "../../constants/index";
import { validateDate } from "../../utils";
import Calendar from "../DatePicker/DatePicker";
import CommentIcon from "../../assets/images/projects/CommentIcon.png";
import ActionIcon from "../../assets/images/projects/ActionIcon.png";

export const UserInputFields: React.FC<{
  page: string;
  projectFields: any;
  setInputValues: (inputValues: any) => void;
  errorMessage: any;
  setErrorMessage: (errorMessage: any) => void;
  isEdit?: boolean;
}> = ({
  page,
  projectFields,
  setInputValues,
  errorMessage,
  setErrorMessage,
  isEdit = false,
}) => {
  const [inputValues, setInputFields] = useState(projectFields);

  useEffect(() => {
    if (isEdit) {
      setInputValues({ ...projectFields });
    }
  }, []);
  const dropdownChangeHandler = (event) => {
    const name = "Project Type";
    const value = event.value;
    const modifiedInputValues = {
      ...inputValues,
      [name]: { ...inputValues[name], value: event },
    };
    setInputFields(modifiedInputValues);
    setInputValues(modifiedInputValues);

    if (value === "" || value === null) {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: `Select ${name}`,
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const handlerDropdownOnBlur = (event, name) => {
    const { value } = inputValues[name];
    const { value: selectedValue } = value || {};
    if (selectedValue === "" || selectedValue === null || !selectedValue) {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: `Select ${name}`,
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const onInputBlur = (event) => {
    // console.log('blur');

    const { target } = event;
    const { name } = target;
    const { value, required } = inputValues[name];

    if (required && value === "") {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: "Required field",
        },
      });
    } else if (
      name === "Lot Acquisition Date" ||
      name === "Expected Completion Date" ||
      name === "Funded Date"
    ) {
      // if (!value) return;
      let { status } = validateDate(value);
      status = status || value === "Input" || value === "";

      // console.log("onblur", value, status);

      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status,
          message: status
            ? ""
            : "Invalid date format,please Enter Date in MM/DD/YYYY format.",
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  const onChangeHandler = (event, value, isDatePickerComponent) => {
    const name = event?.target?.name || value?.name;
    const modifiedInputValues = {
      ...inputValues,
      [name]: { ...inputValues[name], value },
    };
    setInputFields(modifiedInputValues);
    setInputValues(modifiedInputValues);

    if (isDatePickerComponent) {
      if (
        name === "Lot Acquisition Date" ||
        name === "Expected Completion Date" ||
        name === "Funded Date"
      ) {
        setErrorMessage({
          ...errorMessage,
          [name]: {
            ...errorMessage[name],
            status: true,
            message: "",
          },
        });
      }
    }
  };

  return (
    <UserFields isEdit={isEdit}>
      {Object.keys(inputValues).map((item, index) => {
        if (inputValues[item].type === "dropdown") {
          return (
            <Dropdown
              showLabel
              value={inputValues[item].value || ""}
              name={item}
              onChange={dropdownChangeHandler}
              onBlur={handlerDropdownOnBlur}
              options={PROJECT_TYPE_OPTIONS}
              errorMessage={errorMessage["Project Type"]}
              required={inputValues[item]?.required}
            />
          );
        } else if (inputValues[item].type === "textarea") {
          return (
            <TextAreaField
              showLabel
              value={inputValues[item].value}
              name={item}
              onChange={onChangeHandler}
              onBlur={onInputBlur}
              options={PROJECT_TYPE_OPTIONS}
              errorMessage={errorMessage["Project Type"]}
              required={inputValues[item]?.required}
              dataType={inputValues[item]?.dataType}
              maxLength={inputValues[item]?.maxLength}
              showLabelIcon={isEdit}
              labelIcon={item === "Comments" ? CommentIcon : ActionIcon}
            />
          );
        } else if (inputValues[item]?.type === "date") {
          return (
            <Calendar
              name={item}
              value={inputValues[item].value}
              onChange={(value) => {
                onChangeHandler({ target: { name: item } }, value, true);
              }}
              onBlur={() => onInputBlur({ target: { name: item } })}
              error={errorMessage[item]}
            />
          );
        } else {
          if (item === "Project Id"){
            return <></>;
          }
          else if (
            (page === "CreateProject" && item === "Project Id") ||
            item === "Company Id"
          ) {
            return <></>;
          } else if (
            page === "ProjectInformation" &&
            inputValues[item].disabled
          ) {
            return (
              <InputField
                value={inputValues[item].value}
                type={inputValues[item].type}
                name={item}
                showLabel
                disabled
              />
            );
          }
          return (
            <InputField
              value={inputValues[item].value}
              type={inputValues[item].type}
              name={item}
              showLabel
              showPlaceHolder={inputValues[item].showPlaceHolder}
              placeholder={inputValues[item].placeholder}
              onChange={onChangeHandler}
              onBlur={onInputBlur}
              maxLength={inputValues[item].maxLength}
              errorMessage={errorMessage[item]}
              required={inputValues[item]?.required}
              isDollorIcon={item === "Lot cost"}
              dataType={inputValues[item]?.dataType}
              regexPattern={inputValues[item]?.regexPattern}
            />
          );
        }
      })}
    </UserFields>
  );
};

export default UserInputFields;
