import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query getAllCompanies {
    getAllCompanies {
      getAllCompaniesName {
        COMPANY_UUID
        COMPANY_NAME
        COMPANY_TYPE_ID
      }
      getAllCompanyType {
        COMPANY_TYPE
        COMPANY_TYPE_ID
        SIZE_DESCRIPTION
      }
      getAllUserType {
        USER_ROLE
        USER_ROLE_UUID
      }
    }
  }
`;
