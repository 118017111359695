import { gql } from "@apollo/client";

export const DELETE_INSPECTION = gql`
  mutation deleteInspection($inspId: ID!, $pId: ID!) {
    DelInsp(inspId: $inspId, pId: $pId) {
      message
      success
      result
    }
  }
`;
