import styled from "styled-components";
import { Color } from "../../styles/Colors";
import Button from "../Button";

export const Conatiner = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${Color.primary};
`;

export const LogoBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 4px;
  padding: 12px 24px;
  img {
    width: 126px;
    height: 65px;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.white};
  height: 100%;
  padding-top: 21px;
`;

export const IconButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const DetailsContainer = styled.div<{ signInUserSession }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-left: 24px;
  border-left: 1px solid #666;
  color: ${Color.white};
  padding: 20px 24px;
  button {
    border: ${({ signInUserSession }) =>
      signInUserSession ? "none" : "1px solid ${Color.white}"};
    background: none;
    cursor: pointer;
    padding-top: 7px;
  }

  div {
    :nth-child(2) {
      font-size: 14px;
      font-weight: 500;
      color: #aaa;
      margin-top: 4px;
    }
  }
`;

export const DropdownText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  right: 4px;
  border: 2px solid ${Color.gray};
  background-color: ${Color.white};
  color: #bb1328 !important;
  padding: 14.5px 31px;
  font-size: 16px !important;
  font-family: Roboto;
  img {
    margin-right: 18px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const StyledIconButton = styled(Button)<{ isActive }>`
  height: 100%;
  border: none;
  border-radius: 0;
  border-bottom: ${({ isActive }) =>
    isActive ? `4px solid ${Color.secondary}` : "0"};
  & span {
    color: ${({ isActive }) => (isActive ? Color.secondary : Color.white)};
  }
`;

export const SearchInput = styled.input`
  padding: 12px 16px;
  margin-right: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  width: 250px; 
  max-width: 500px; 

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

export const SearchButton = styled.button`
  background-color: ${Color.primary};
  color: ${Color.white};
  border: none;
  padding: 8px 16px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Color.darkPrimary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${Color.lightPrimary};
  }

  &:disabled {
    background-color: ${Color.gray};
    cursor: not-allowed;
  }
`;

