import styled from "styled-components";
import Button from "../Button";
import { Color } from "../../styles/Colors";

export const UserFields = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 24px;
  margin: 16px 0;
  background: ${Color.white}fff;
  border: 1px solid ${Color.gray};
  border-radius: 5px;
  overflow: auto;

  > * {
    flex: 1 1 378px;
  }
`;

export const UserField = styled.div`
  //styleName: body-small;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #686b6d;
`;
export const UserFieldValue = styled.div`
  //styleName: body-normal;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  line-height: 19.2px;
  color: ${Color.darkGray};
`;

export const UserValues = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    align-self: flex-end;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
`;

export const LoaderContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
`;
export const CustomEditButton = styled(Button)`
  align-self: flex-end !important;
`;

export const LabelConatiner = styled.div`
display: flex;
gap: 8px;
align-items: center;
`;
