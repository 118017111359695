import React from "react";
import {
  AnalyicsConatiner,
  HeadingText,
  LoaderContainer,
  CookiesMessage,
} from "./AnalyicsPreview.styles";
import PuffLoader from "react-spinners/PuffLoader";
import { AnalyticsPreviewProps } from "../../../../type";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "tableau-viz": any;
    }
  }
}

const AnalyicsPreview: React.FC<AnalyticsPreviewProps> = ({
  header,
  cookiesEnabledMessage,
  token,
  src,
  isLoading,
}) => {
  return (
    <AnalyicsConatiner>
      {header &&
        (typeof header === "string" ? (
          <>
            <HeadingText>{header}</HeadingText>
            <CookiesMessage>{cookiesEnabledMessage}</CookiesMessage>
          </>
        ) : (
          header
        ))}
      <div>
        {isLoading ? (
          <LoaderContainer>
            <PuffLoader loading={isLoading} size={100} />
          </LoaderContainer>
        ) : (
          <tableau-viz
            id="tableau-viz"
            src={src}
            width="100%"
            height="calc(100vh - 270px)"
            token={token}
            device="tablet"
          ></tableau-viz>
        )}
      </div>
    </AnalyicsConatiner>
  );
};

export default AnalyicsPreview;
