import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const StyledDropdown = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: #686b6d;

  .select-dropdown > div {
    height: 40px;
    border: 1px solid ${({ error }) => (error ? Color.outlineVarient : Color.error)};
  }

  .css-1xc3v61-indicatorContainer {
    color: unset;
  }
  .css-1jqq78o-placeholder {
    color: black;
  }

  .react-select__menu-list {
    color: ${Color.darkGray};
    background: ${Color.white};
    border: 1px solid ${Color.gray};
  }

  .react-select__option--is-selected {
    background: #deebff;
    color: ${Color.darkGray};
  }

  .react-select__menu .react-select__option--is-selected {
    background: #deebff;
  }
`;
// .react-select__option:hover {
//   background:#F5F8FF;
// }

export const ErrorMessage = styled.p<{ errorMessageSize: string }>`
  color: ${Color.error} !important;
  font-size: ${({ errorMessageSize }) =>
    errorMessageSize ? errorMessageSize : "14px"} !important;
  pointer-events: none;
  padding-left: 8px;
  padding-top: 4px;
`;

export const CustomLabel = styled.label`
  &.required:after {
    content: " *";
  }
`;
