import { graphQLFetch } from "../../../utils";
// import { SET_DELETE_BUDGET_ITEM } from "../../../constants";
import { UPDATE_BUDGET_ITEM } from "../../queries";

export const updateBudgetItem =
  (item, callback) => async (dispatch, getState) => {
    const variables = {
      input: item,
    };
    const data = await graphQLFetch(
      UPDATE_BUDGET_ITEM,
      variables,
      "mutation",
      getState
    );
    const { UpdateBudgetItem } = data || {};
    callback(UpdateBudgetItem);

    return UpdateBudgetItem;
  };
