import { forwardRef } from "react";

import { StyledSkeleton } from "./Skeleton.styles";

export const Skeleton: React.FC<{
  layout?: { width: string; height: string }[];
  spacing?: string;
  shape?: "rectangle" | "circle";
  fullHeight?: boolean;
}> = forwardRef(
  (
    {
      layout = [
        { width: "624px", height: "48px" },
        { width: "432px", height: "24px" },
      ],
      spacing = "8px",
      shape = "rectangle",
      fullHeight = false,
      ...props
    },
    ref
  ) => {
    return (
      <StyledSkeleton
        {...{ layout, ref, spacing, shape, fullHeight }}
        {...props}
      >
        {layout.map((_, index) => {
          const block = `block-${index}`;
          return <div className={block} key={block} />;
        })}
      </StyledSkeleton>
    );
  }
);

export default Skeleton;
