import React from "react";

export const IconChevronRight = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 4L11.2567 5.41L16.8367 11H4.66675V13H16.8367L11.2567 18.59L12.6667 20L20.6667 12L12.6667 4Z"
        fill="#323133"
      />
    </svg>
  );
};

export default IconChevronRight;
