// @ts-nocheck
import { gql } from "@apollo/client";
import apolloClient from "./withApollo";
import customApolloClient from "./withApolloUsingApi";
import { signOut } from "../../utils/cognito-services";

// eslint-disable-next-line complexity
export const graphQLFetch = async (
  query,
  variables = {},
  queryType = "query",
  state,
  isNewApolloClient = false
) => {
  const { userInfo } = state();
  const { signInUserSession } = userInfo || {};
  const { idToken } = signInUserSession || {};
  const { jwtToken: userAccessToken } = idToken || {};
  try {
    let data: any;
    const queryLiteral = gql`
      ${query}
    `;
    if (isNewApolloClient) {
      data = await customApolloClient.mutate({
        mutation: queryLiteral,
        variables,
        context: {},
      });
    } else {
      if (queryType === "query") {
        data = await apolloClient.query({
          query: queryLiteral,
          variables,
          context: {
            headers: {
              authorization: userAccessToken ? `Bearer ${userAccessToken}` : "",
            },
          },
        });
      } else {
        data = await apolloClient.mutate({
          mutation: queryLiteral,
          variables,
          context: {
            headers: {
              authorization: userAccessToken ? `Bearer ${userAccessToken}` : "",
            },
          },
        });
      }
    }
    return data?.data;
  } catch (error) {
    const errorMessage = `level-capital > graphQLFetch > error > ${JSON.stringify(
      error
    )} > apolloFetch`;
    const { networkError } = error || {};
    const { result } = networkError || {};
    const { errors } = result || {};
    console.error(errorMessage);
    if (
      Array.isArray(errors) &&
      errors.length > 0 &&
      errors[0].errorType === "UnauthorizedException"
    ) {
      signOut({ user: userInfo }, () => {
        window.location.href = "/";
      });
    }
  }
};
