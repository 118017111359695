import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { Button } from "../Button/Button";
import { Color } from "../../styles/Colors";

export const BudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BudgetMetaData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .btn-primary {
    margin-left: auto;
  }
`;

export const BudgetInfo = styled.div`
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: ${Color.darkGray};
  display: flex;
  gap: 6px;
`;

export const CustomDialogFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr 3fr 30px;
  /* padding-left: 8px; */
  padding-bottom: 8px;
  border-bottom: 1px solid ${Color.gray};
  margin-bottom: 16px;
  margin-right: -0.5rem;
  margin-left: 8px;
  margin-top: -0.5rem;

  > div {
    margin: 0px;
  }

  .delete-icon {
    margin-top: 30px;
    align-self: center;
  }
`;

export const BudgetItemDialog = styled(Dialog)`
  padding: 16px;
  padding-right: 0px;
  .p-dialog-header,
  .p-dialog-footer {
    padding: 16px;
  }

  .p-dialog-content {
    overflow-y: unset;
    padding: 16px;
  }
`;
export const AddItemButton = styled(Button)`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButton = styled.div`
  display: flex;
  padding: 2px 0px;
  gap: 26px;

  button {
    border: none !important;
    padding: 0;

    &:focus {
      border: none !important;
      background-color: unset !important;
    }
  }
`;

export const TableConatiner = styled.div`
  margin-bottom: 16px;
  th {
    background-color: #ebecee;
  }
  .p-datatable .p-datatable-loading-overlay {
    background-color: unset;
    top: 100%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, 100%);
  }
`;
