import { SET_PROJECTS } from "../../constants";

export const projects = (
  state: any = {
    isLoading: true,
  },
  action: any
) => {
  switch (action.type) {
    case SET_PROJECTS:
      return { ...action.payload, isLoading: false };
    default:
      return state;
  }
};
