// @ts-nocheck
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "../../assets/images/Common/EditIcon.png";
import DeleteIcon from "../../assets/images/Common/deleteIcon.png";

import { Button } from "../Button/Button";
import { Toolbar } from "primereact/toolbar";
import {
  BudgetContainer,
  BudgetInfo,
  CustomDialogFooter,
  ActionButton,
  TableConatiner,
} from "./BudgetList.styles";
import CreateBudgetModel from "./components/CreateBudgetModel/CreateBudgetModel";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";
import Modal from "../Modal/Modal";
import { PrimeReactProvider } from "primereact/api";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getBudgetItems,
  createBudgetItem,
  deleteBudgetItem,
  getBudgetCategories,
  selectBudgetCategories,
} from "../../redux";
import { formatBudgetItems } from "../../utils/common/validation";
import { updateBudgetItem } from "../../redux/actions/set/setUpdateBudgetItem";
import { formatCurrency } from "../../utils";
import EmptyTable from "../UnitInformation/EmptyTable/EmptyTable";
import useReadOnlyUser from "../../hooks/useReadOnlyUser";
import { Color } from "../../styles/Colors";
import { FileUpload } from "../FileUpload/FileUpload";
import { CustomToolbar } from "../ToolBar/CustomToolbar";

export const itemsFields = ["Category", "Budget Item Description", "Amount"];

export const BudgetList: React.FC<{
  pId: string | undefined;
  pName: string | undefined;
}> = ({ pId, pName }) => {
  const dispatch = useDispatch();

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const bdgtCategoryOptions = useSelector(selectBudgetCategories).map(
    (item) => ({ label: item.bdgtCategory, value: item.bdgtCategory })
  );

  const isReadonlyUser = useReadOnlyUser();

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const getAllBudgets = () => {
    setLoading(true);
    dispatch(getBudgetCategories());
    dispatch(
      getBudgetItems({ pId }, (GetBudgetItems) => {
        setProducts(GetBudgetItems);
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    getAllBudgets();
  }, []);

  const openNew = () => {
    setProduct(null);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setProductDialog(false);
    setProduct(null);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const saveProduct = (items) => {
    setBtnLoading(true);
    const modifiedItems = formatBudgetItems(items, pId);
    dispatch(
      createBudgetItem({ items: modifiedItems }, (res) => {
        if (res?.success) {
          getAllBudgets();
          notifySuccess(
            `${
              product != null && product?.category
                ? "Budget item updated"
                : "Budget item created"
            } successfully!`
          );
        } else {
          notifyError("Failed to create budget item!");
        }
        setBtnLoading(false);
        hideDialog();
      })
    );
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteBudget = () => {
    const { bdgtLnItmId } = product || {};
    setBtnLoading(true);
    dispatch(
      deleteBudgetItem({ bdgtLnItmId: bdgtLnItmId, pId }, (res) => {
        if (res?.success) {
          getAllBudgets();
          notifySuccess("Budget item deleted successfully!");
        } else {
          notifyError("Failed to delete budget item!");
        }
        setBtnLoading(false);
        setDeleteProductDialog(false);
      })
    );
  };

  const openPopUp = () => {
    setPopUpOpen(true);
  };
  
  const closePopUp = () => {
    setPopUpOpen(false);
  };
  
  const sum =
    products !== null &&
    products?.reduce((acc, obj) => acc + Number(obj.amt), 0);

  const leftToolbarTemplate = () => {
    return (
      <BudgetInfo>
        <span>Total Budget Amount: {formatCurrency(sum)}</span>|
        <span>Total Budget Items: {products !== null && products?.length}</span>
      </BudgetInfo>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        type="button"
        onClick={openNew}
        hasStartIcon={true}
        className="btn-primary"
        disabled={isReadonlyUser}
      >
        Create Budget Items
      </Button>
    );
  };

  const centerToolbarTemplate = () => {
    return (
      <>
      <Button onClick={openPopUp} >
        Budget Upload
      </Button>
      {popUpOpen && <FileUpload open={popUpOpen} onClose={closePopUp} singleFile={true}/>}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <ActionButton>
        <Button
          type="button"
          onClick={() => editProduct(rowData)}
          className="btn-default"
          disabled={isReadonlyUser}
        >
          <img src={EditIcon} alt="editIcon" />
        </Button>
        <Button
          type="button"
          onClick={() => confirmDeleteProduct(rowData)}
          className="btn-default"
          disabled={isReadonlyUser}
        >
          <img src={DeleteIcon} alt="deleteIcon" />
        </Button>
      </ActionButton>
    );
  };

  const editBudget = ([inputValues]) => {
    const {
      Amount: amt,
      Category,
      "Budget Item Description": bdgtDesc,
    } = inputValues;
    const { label: bdgtCategory } = Category;

    const { bdgtLnItmId, bdgt_line_item_version: version } = product || {};
    setBtnLoading(true);
    dispatch(
      updateBudgetItem(
        {
          bdgtLnItmId: bdgtLnItmId,
          pId,
          bdgtDesc,
          amt,
          bdgtCategory,
          bdgt_line_item_version: version,
        },
        (res) => {
          if (res?.success) {
            getAllBudgets();
            notifySuccess("Budget item updated successfully!");
          } else {
            notifyError("Not able to edit budget");
          }
          setBtnLoading(false);
          hideDialog();
        }
      )
    );
  };

  const deleteProductDialogFooter = (
    <CustomDialogFooter>
      <Button
        type="button"
        className="btn-default"
        onClick={hideDeleteProductDialog}
      >
        Cancel
      </Button>
      <Button
        type="button"
        className="btn-primary"
        onClick={deleteBudget}
        isLoading={btnLoading}
      >
        Delete
      </Button>
    </CustomDialogFooter>
  );

  const colunmStyle = {
    padding: "14px 16px",
    border: `1px solid ${Color.gray}`,
  };

  return (
    <BudgetContainer>
      <PrimeReactProvider>
        <div>
          <StyledToast />
          <div className="card">
          <CustomToolbar
            style={{ backgroundColor: "unset", border: "none" }} 
            className="mb-4" 
            left={leftToolbarTemplate}
            center={centerToolbarTemplate}
            right={rightToolbarTemplate}
          />

            <TableConatiner>
              <DataTable
                value={products}
                showGridlines
                scrollable
                scrollHeight="400px"
                size="large"
                tableStyle={{
                  minWidth: "50rem",
                  border: `1px solid ${Color.gray}`,
                }}
                emptyMessage={<EmptyTable tableName={"Budget"} />}
                loading={loading}
              >
                <Column
                  sortable
                  field="bdgtCategory"
                  header="Category"
                  style={colunmStyle}
                  body={({ bdgtCategory }) =>
                    bdgtCategory === "Select Category"
                      ? ""
                      : `${
                          bdgtCategory.slice(0, 1).toUpperCase() +
                          bdgtCategory.slice(1)
                        }`
                  }
                ></Column>
                <Column
                  sortable
                  field="bdgtDesc"
                  header="Description"
                  style={colunmStyle}
                ></Column>
                <Column
                  sortable
                  field="amt"
                  header="Amount"
                  style={colunmStyle}
                  body={({ amt }) => formatCurrency(amt)}
                ></Column>
                <Column
                  sortable
                  field="updatedOn"
                  header="Updated on"
                  style={colunmStyle}
                ></Column>
                <Column
                  field="bdgt_line_item_version"
                  header="Version"
                  style={colunmStyle}
                  body={({ bdgt_line_item_version }) =>
                    `${bdgt_line_item_version}`
                  }
                ></Column>
                <Column
                  field="actions"
                  header="Actions"
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{
                    minWidth: "12rem",
                    padding: "14px 16px",
                    border: `1px solid ${Color.gray}`,
                  }}
                  alignFrozen="right"
                  frozen
                ></Column>
              </DataTable>
            </TableConatiner>
          </div>

          {productDialog && (
            <CreateBudgetModel
              onClose={hideDialog}
              isOpen={productDialog}
              product={product}
              saveProduct={saveProduct}
              editBudget={editBudget}
              pName={pName}
              btnLoading={btnLoading}
              categoryOptions={bdgtCategoryOptions}
            />
          )}

          {deleteProductDialog && (
            <Modal
              open={deleteProductDialog}
              header="Delete Budget Item?"
              modal
              footer={deleteProductDialogFooter}
              onClose={hideDeleteProductDialog}
            >
              <p>Are you sure, you want to delete this budget item?</p>
            </Modal>
          )}
        </div>
      </PrimeReactProvider>
    </BudgetContainer>
  );
};

export default BudgetList;
