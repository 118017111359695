import { SET_BUDGET_CATEGORIES } from "../../constants";

export const getBudgetCategories = (state: any = null, action: any) => {
  switch (action.type) {
    case SET_BUDGET_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};
