import styled from "styled-components";
import { Color } from "../../styles/Colors";
import Button from "../Button";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 12px 24px;
  align-items: center;
  border-bottom: 1px solid ${Color.outlineVarient};
  background: var(--surface, ${Color.white});
`;

export const SelectBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  img {
    width: 22px;
    height: 22px;
  }
`;

export const Cards = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 782px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Type = styled.div`
  color: #000;

  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Container = styled.div`
  background-color: #ebf1ff;
  display: flex;
  height: calc(100vh-(93px));
  width: 100%;
  padding: 0px 24px;
`;
export const SubHeading = styled.p`
  background-color: #ebf1ff;
  display: flex;
  height: calc(100vh-(93px));
  width: 100%;
  padding-left: 24px;
`;

export const DeleteButton = styled(Button)`
  align-self: flex-end;
  margin-left: auto;
  border: none !important;
  background: unset !important;
`;

export const BackButton = styled(Button)`
  border: none !important;
  background: unset !important;
`;
