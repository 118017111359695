import { gql } from "@apollo/client";

export const DELETE_UNIT = gql`
  mutation deleteUnit($unitId: ID!, $pId: ID!) {
    DeleteUnit(unitId: $unitId, pId: $pId) {
      message
      success
      result
    }
  }
`;
