import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";

import {
  UserFields,
  UserField,
  UserFieldValue,
  UserValues,
  ProjectInfoContainer,
  ActionButtons,
  LoaderContainer,
  CustomEditButton,
  LabelConatiner,
} from "./ProjectInformation.styles";
import { toast } from "react-toastify";
import Button from "../Button";
import UserInputFields from "../UserInputFields/UserInputFields";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";

import {
  getProjectById,
  projectByIdSelector,
  setUpdateProject,
} from "../../redux";
import {
  hasAnyErrorStatusFalse,
  mapCurrentProjectFields,
  formatObjToKeyValuePair,
  projectFields,
  formatCurrency,
} from "../../utils";
import { PROJECT_DEFAULT_INPUT_ERROR } from "../../constants";
import useReadOnlyUser from "../../hooks/useReadOnlyUser";
import CommentIcon from "../../assets/images/projects/CommentIcon.png";
import ActionIcon from "../../assets/images/projects/ActionIcon.png";
import { Color } from "../../styles/Colors";

export const ProjectInformation: React.FC<{
  projectId: string | undefined;
  companyId: string | undefined;
}> = ({ projectId, companyId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValues, setInputValues] = React.useState({});
  const [isProjectLoading, setIsProjectLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState(PROJECT_DEFAULT_INPUT_ERROR);

  const { currentProject = {} } = useSelector(projectByIdSelector);
  const isReadonlyUser = useReadOnlyUser();

  const [isEdit, setEdit] = React.useState(false);

  const onEditHandler = () => {
    setEdit(true);
  };

  const onCancelHandler = () => {
    setEdit(false);
  };

  const onSaveHandler = () => {
    const items = formatObjToKeyValuePair(inputValues);
    let modifiedError = errorMessage;
    for (let inputFieldName in projectFields) {
      const { required } = projectFields[inputFieldName];
      const value = inputValues[inputFieldName];
      if (required && (!value || value === "")) {
        modifiedError = {
          ...modifiedError,
          [inputFieldName]: {
            ...modifiedError[inputFieldName],
            status: false,
            message:
              inputFieldName === "Project Type"
                ? "Select Project Type"
                : "Required field",
          },
        };
      }
    }

    setErrorMessage(modifiedError);
    if (
      !hasAnyErrorStatusFalse(modifiedError) &&
      !hasAnyErrorStatusFalse(errorMessage)
    ) {
      setIsLoading(true);
      dispatch(
        setUpdateProject(
          { items: { ...items, cId: companyId } },
          (projectInfo) => {
            const { message, success } = projectInfo || {};
            if (success) {
              notifySuccess("Project Updated Successfully", () => {
                setIsLoading(false);
                setIsProjectLoading(true);
                dispatch(getProjectById({ companyId, projectId })).then(() => {
                  setIsProjectLoading(false);
                  setEdit(false);
                });
              });
            } else {
              setIsLoading(false);
              notifyError(message || "Not able to update project");
            }
          }
        )
      );
    }
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message, onclose) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: onclose,
    });
  };

  React.useEffect(() => {
    if (projectId) {
      setIsProjectLoading(true);
      dispatch(getProjectById({ companyId, projectId })).then(() => {
        setIsProjectLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = mapCurrentProjectFields(currentProject);

  const renderKeyValueFields = (key, value) => {
    const { value: inputValue } = value || {};
    const { label: dropdownSelectedValue } = inputValue || {};

    switch (key) {
      case "Project Type":
        return dropdownSelectedValue;
      case "Expected Marketing Time":
        return inputValue > 0 ? `${inputValue} Days` : "-";

      case "Lot cost":
        return inputValue > 0 ? formatCurrency(inputValue) : "-";

      default:
        return inputValue || "-";
    }
  };

  return (
    <ProjectInfoContainer>
      <StyledToast />
      {isProjectLoading ? (
        <LoaderContainer>
          <BeatLoader
            color={Color.darkBlue}
            loading
            margin={4}
            size={15}
            aria-label="Loading Beats"
            data-testid="loader"
          />
        </LoaderContainer>
      ) : (
        <>
          {isEdit ? (
            <ActionButtons>
              <Button
                type="button"
                className="btn-default"
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn-primary"
                onClick={onSaveHandler}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ClipLoader
                    color={Color.white}
                    size={15}
                    cssOverride={{ marginLeft: "10px", marginRight: "10px" }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </ActionButtons>
          ) : (
            <CustomEditButton
              type="button"
              className={"btn-default"}
              onClick={onEditHandler}
              disabled={isReadonlyUser}
            >
              Edit
            </CustomEditButton>
          )}
          <UserFields>
            {isEdit && (
              <UserInputFields
                page="ProjectInformation"
                projectFields={fields}
                setInputValues={setInputValues}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                isEdit={true}
              />
            )}
            {!isEdit &&
              Object.entries(fields || {}).map(([key, value]) => {
                if (key === "Project Id") {
                  return null;
                }
                if (
                  key === "Company Id" ||
                  key === "Comments" ||
                  key === "Action Plan"
                ) {
                  return null;
                }
                return (
                  <UserValues key={key}>
                    <UserField>{key}</UserField>
                    <UserFieldValue>
                      {renderKeyValueFields(key, value)}
                    </UserFieldValue>
                  </UserValues>
                );
              })}
          </UserFields>
          {!isEdit && (
            <UserFields>
              {Object.entries(fields || {}).map(([key, value]) => {
                if (key === "Comments" || key === "Action Plan")
                  return (
                    <UserValues key={key}>
                      <LabelConatiner>
                        <img
                          src={key === "Comments" ? CommentIcon : ActionIcon}
                          alt={`${key} icon`}
                        />
                        <UserField>{key}</UserField>
                      </LabelConatiner>
                      <UserFieldValue>
                        {renderKeyValueFields(key, value)}
                      </UserFieldValue>
                    </UserValues>
                  );
              })}
            </UserFields>
          )}
        </>
      )}
    </ProjectInfoContainer>
  );
};

export default ProjectInformation;
