import styled from "styled-components";
import BackgroundImage from "../../assets/images/Login/login_Bg.png";
import { Color } from "../../styles/Colors";

export const BackgroundImageConatiner = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  display: block;
  width: 100%;
  min-height: 100vh;
  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  max-height: fit-content;
`;
export const Container = styled.div`
  overflow: auto;
  min-height: 100vh;
  background-color: ${Color.primary}bf;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  padding-left: 95px;
  display: flex;
  align-items: center;
`;

export const LogoBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  :nth-child(1) {
    width: 160px;
    height: 85px;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
  margin-right: 160px;
`;

export const Heading = styled.p`
  display: flex;
  align-items: center;
  max-width: 45%;
  font-size: 64px;
  font-weight: 500;
  margin: 0px;
  color: ${Color.white};
`;

export const LoginForm = styled.div`
  display: flex;
  width: 550px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 36px 24px 13px 24px;
  background-color: ${Color.white};
  border-radius: 16px;
  color: #000;
`;

export const LoginFormHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  gap: 16px;
  text-align: center;
  div {
    span {
      color: #000;
      font-size: 32px;
      font-weight: 300;
    }
    :nth-child(2) {
      font-weight: 700;
      margin-left: 8px;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: #666;
  }
`;

export const InputContainer = styled.form`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 14px 24px 29px;

  button {
    align-self: center;
    padding: 16px 24px;
    border: 0px;
    background-color: ${Color.primary};
    font-family: Roboto, "lato", sans-serif;
    color: ${Color.white};
    font: 500 16px "Roboto", "lato", sans-serif;
    border-radius: 4px;
  }

  .disabled {
    background-color: grey;
    opacity: 0.4;
  }

  a {
    cursor: pointer;
    color: ${Color.hyperlink};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    align-self: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    color: ${Color.white};
    background-color: ${Color.primary};
    border-radius: 4px;
    padding: 8px 24px;
    font-weight: 500;
  }
`;

export const CustomModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CustomHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
`;
