import { SET_BUDGET_ITEMS } from "../../constants";

export const budgetItems = (state: any = {}, action: any) => {
  switch (action.type) {
    case SET_BUDGET_ITEMS:
      return action.payload;
    default:
      return state;
  }
};
