import { gql } from "@apollo/client";

export const UPDATE_UNIT = gql`
  mutation updateUnit($input: UpdatedUnitInput) {
    UpdateUnit(input: $input) {
      message
      success
      result
    }
  }
`;
