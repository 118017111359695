import React from "react";
import { StyledCustomToolbar } from "./CustomToolbar.styles";


export const CustomToolbar = ({ left, center, right, ...props  }) => {
    return (
      <StyledCustomToolbar {...props }>
        <div className="toolbar-left">
          {left()}
        </div>
        <div className="toolbar-center-right">
          <div className="toolbar-center">
            {center()}
          </div>
          <div className="toolbar-right">
            {right()}
          </div>
        </div>
      </StyledCustomToolbar>
    );
  };
