import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const TabContainer = styled.div`
  width: 100%;
`;

export const TabHeader = styled.div`
  display: flex;
  gap: 32px;
  border-bottom: 1px solid ${Color.gray};
`;

export const TabButton = styled.button<{ active: boolean }>`
  color: ${({ active }) => (active ? Color.primary : "#686B6D")};
  border: none;
  border-bottom: ${({ active }) => (active ?`4px solid ${Color.primary}` : "#686B6D")};
  outline: none;
  cursor: pointer;
  padding: 16px 4px;
  font-size: 16px;
  font-weight: 600;
  background-color: unset;
  display: flex;
  justify-content: center;
`;

export const TabContent = styled.div`
  padding-top: 16px;
`;
