import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import {
  BackgroundImageConatiner,
  Container,
  LogoBox,
  ContentBox,
  Heading,
  LoginForm,
  LoginFormHeading,
  InputContainer,
} from "./Login.styles";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import EmailIcon from "../../assets/images/Login/email-icon.svg";
import PasswordIcon from "../../assets/images/Common/Password_icon.svg";
import LevelLogo from "../../assets/images/Common/Vertical_iq.png";
import { setUserInfo, setUserVerificationEmail } from "../../redux";

import { signIn, forgotPassword } from "../../utils/cognito-services";
import ForgetModal from "./components/ForgetModal/ForgetModal";
import {
  hasAnyErrorStatusFalse,
  isValidEmail,
  isValidPassword,
} from "../../utils";
import { ErrorMessage } from "../InputField/InputField.styles";
import { Color } from "../../styles/Colors";

export const Login = () => {
  const defaultErrorMessage = {
    username: { status: true, message: "" },
    password: { status: true, message: "" },
    onLoginError: { status: true, message: "" },
  };
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [forgetModal, setForgetModal] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const proceedToLogin = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage({
      ...errorMessage,
      onLoginError: {
        status: true,
        message: errorMessage.onLoginError.message,
      },
    });
    const isAnyError = hasAnyErrorStatusFalse({
      ...errorMessage,
      onLoginError: { status: true, message: "" },
    });
    if (isAnyError) {
      setIsLoading(false);
    } else if (username === "" || password === "") {
      setIsLoading(false);
      setErrorMessage({
        ...errorMessage,
        username: {
          status: false,
          message: "",
        },
        password: {
          status: false,
          message: "",
        },
      });
    } else {
      signIn(username.toLowerCase(), password, ({ user, errMessage }) => {
        setIsLoading(false);
        if (errMessage) {
          setErrorMessage({
            ...errorMessage,
            username: {
              status: true,
              message: "",
            },
            password: {
              status: true,
              message: "",
            },
            onLoginError: {
              status: false,
              message: "Please enter valid Email Address & Password.",
            },
          });
        } else {
          setErrorMessage(defaultErrorMessage);
          dispatch(setUserInfo(user));
          const { signInUserSession } = user || {};
          const { idToken } = signInUserSession || {};
          const { payload } = idToken || {};
          const customPayload = payload || {};
          const customUserType = customPayload["custom:userType"]
            ? customPayload["custom:userType"].toLowerCase()
            : "";
          const companyId = customPayload["custom:companyId"]
            ? customPayload["custom:companyId"].toLowerCase()
            : "";
          if (customUserType === "admin") {
            navigate("/home/create-new-user");
          } else if (
            customUserType === "accountexecutive" ||
            customUserType === "accountmanager"
          ) {
            navigate(`/home/${companyId}`);
          }
        }
      });
    }
  };

  const handleForget = () => {
    setErrorMessage(defaultErrorMessage);
    setForgetModal(true);
  };

  const onBlurUsername = (event) => {
    const { status } = isValidEmail(username);
    setErrorMessage({
      ...errorMessage,
      username: {
        status,
        message: status ? "" : "Please enter a valid email address",
      },
      onLoginError: {
        status: true,
        message: "",
      },
    });
  };

  const onBlurPassword = (event) => {
    const { status } = isValidPassword(password);
    setErrorMessage({
      ...errorMessage,
      password: {
        status,
        message: status ? "" : "Password Requirements not met",
      },
      onLoginError: {
        status: true,
        message: "",
      },
    });
  };

  const handleSubmit = (email) => {
    setForgetModal(false);
    setEmailSent(true);
    forgotPassword(email);
  };

  const sendVerificationEmail = (email) => {
    dispatch(setUserVerificationEmail(email));
    navigate("/reset-password");
  };

  return (
    <>
      <BackgroundImageConatiner></BackgroundImageConatiner>
      <Container>
        <ContentBox>
          <Heading>Securely Access Your Project Data and Analytics</Heading>
          <LoginForm onSubmit={proceedToLogin} autoComplete="off">
            <LoginFormHeading>
              <LogoBox>
                <img src={LevelLogo} alt="level" />
              </LogoBox>

              <p>Enter your username and password to access your account</p>
            </LoginFormHeading>
            <InputContainer autoComplete="off">
              <InputField
                icon={EmailIcon}
                name={"Email"}
                showPlaceHolder
                onChange={(event, value) => {
                  setErrorMessage({
                    ...errorMessage,
                    username: { status: true, message: "" },
                  });
                  setUsername(value);
                }}
                errorMessage={errorMessage.username}
                onBlur={onBlurUsername}
                disabled={forgetModal}
                maxLength={200}
                autoComplete="off"
              />
              <div>
                <InputField
                  icon={PasswordIcon}
                  name={"Password"}
                  type="password"
                  showPlaceHolder
                  maxLength={16}
                  onChange={(event, value) => {
                    setErrorMessage({
                      ...errorMessage,
                      password: { status: true, message: "" },
                    });
                    setPassword(value);
                  }}
                  onBlur={onBlurPassword}
                  errorMessage={errorMessage.password}
                  disabled={forgetModal}
                  autoComplete="new-password"
                  regexPattern={/^[^\\]*$/}
                />
                {!errorMessage.onLoginError.status && (
                  <ErrorMessage>
                    {errorMessage.onLoginError.message}
                  </ErrorMessage>
                )}
              </div>

              <Button
                onClick={proceedToLogin}
                disabled={isLoading}
                className="btn-primary"
                type="submit"
              >
                {isLoading ? (
                  <ClipLoader
                    color={Color.white}
                    size={15}
                    cssOverride={{ marginLeft: "10px", marginRight: "10px" }}
                  />
                ) : (
                  "Login"
                )}
              </Button>
              <a href="#" onClick={handleForget}>
                Forgot Password?
              </a>
            </InputContainer>
          </LoginForm>
        </ContentBox>
        <ForgetModal
          {...{
            forgetModal,
            setForgetModal,
            handleForget,
            isEmailSent,
            setEmailSent,
            sendVerificationEmail,
            handleSubmit,
          }}
        />
      </Container>
    </>
  );
};

export default Login;
