import { graphQLFetch } from "../../../utils";
import { CREATE_PROJECT } from "../../queries";
import { createProject as setCreateProject } from "../../reducers/createProject";

export const createProject =
  ({ items }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      input: items,
    };
    const data = await graphQLFetch(
      CREATE_PROJECT,
      variables,
      "mutation",
      getState
    );
    const { CreateProject } = data || {};
    callback(CreateProject);

    return dispatch(setCreateProject(CreateProject));
  };
