import { combineReducers } from "redux";
import { projects } from "./projects";
import { userInfo } from "./userInfo";
import { getVerifiedEmail } from "./getVerifiedEmail";
import { apolloClient } from "./apolloClient";
import { projectById } from "./projectById";
import { createProject } from "./createProject";
import { updateProject } from "./updateProject";
import { deleteProject } from "./deleteProject";
import { budgetItems } from "./budgetItems";
import { getTableauToken } from "./getTableauToken";
import { units } from "./units";
import { inspections } from "./inspections";
import { getAllCompanies } from "./getAllCompanies";
import { getBudgetCategories } from "./getBudgetCategories";

export const reducers = combineReducers({
  projects,
  userInfo,
  getVerifiedEmail,
  apolloClient,
  projectById,
  createProject,
  updateProject,
  deleteProject,
  budgetItems,
  getTableauToken,
  units,
  inspections,
  getAllCompanies,
  getBudgetCategories,
});
