import React from "react";
import Select from "react-select";
import { StyledDropdown, ErrorMessage, CustomLabel } from "./Dropdown.styles";
import { DropdownProps } from "../../types";

export const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  options,
  name,
  showLabel,
  placeholder,
  errorMessage = { status: true, message: "", size: "14px" },
  onBlur = (event, name) => {},
  required,
  disabled,
}) => {
  const handleOnBlur = (event) => {
    onBlur(event, name);
  };
  return (
    <StyledDropdown error={errorMessage?.status}>
      {showLabel && name && (
        <CustomLabel className={required && "required"}>{name}</CustomLabel>
      )}
      <div>
        <Select
          name={name}
          classNamePrefix="react-select"
          className={"select-dropdown"}
          value={value}
          onChange={onChange}
          options={options}
          placeholder={placeholder || "Select"}
          onBlur={handleOnBlur}
          isDisabled={disabled}
        />
        {errorMessage?.message && (
          <ErrorMessage errorMessageSize={errorMessage?.size}>
            {errorMessage.message}
          </ErrorMessage>
        )}
      </div>
    </StyledDropdown>
  );
};

export default Dropdown;
