import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { Color } from "../../styles/Colors";
import BackgroundImg from "../../assets/images/Login/login_Bg.png";

export const WapperConatiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackgroundImage = styled.div`
  z-index: unset;
  position: fixed;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  min-height: 100vh;
  background: url(${BackgroundImg});
  background-size: cover;
  max-height: fit-content;
`;

export const Container = styled.div`
  overflow: auto;
  min-height: 100vh;
  background-color: ${Color.primary}bf;
  position: absolute;
  top: 88px;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Form = styled.div`
  display: flex;
  width: 520px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 36px 24px 13px 24px;
  background-color: ${Color.white};
  border-radius: 16px;
  color: #000;
`;

export const UserText = styled.div`
  font-size: 24px;
  padding-left: 14px;
`;

export const UserFields = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; // Adjust height as needed
  z-index: 999;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
`;

export const UserBtn = styled.div`
  display: flex;
  gap: 16px;
`;

export const TooltipInformation = styled.div`
  ul {
    li {
      list-style: none;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: ${Color.error} !important;
  font-size: 14px;
  pointer-events: none;
  padding-top: 4px;
`;

export const StyledToast = styled(ToastContainer)`
  .Toastify__toast--success {
    z-index: 9999;
    background: #eafaf1;
    border: 1px solid #2ecc71;
    color: ${Color.darkGray};
    display: flex;
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    top: 30px;
  }
  .Toastify__toast--error {
    z-index: 9999;
    border: 1px solid #e74c3c;
    color: ${Color.darkGray};
    display: flex;
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    top: 30px;
  }
  .Toastify__toast-body {
    margin-left: 10px;
  }
  .Toastify__close-button {
    display: none;
  }
`;
