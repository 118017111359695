import styled from "styled-components";
import { Color } from "../../../../styles/Colors";

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    color: ${Color.white};
    background-color: ${Color.primary};
    border-radius: 4px;
    padding: 8px 24px;
    font-weight: 500;
  }
`;

export const CustomModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CustomHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
`;
