import { setProjectById } from "../set/setProjectById";
import { graphQLFetch } from "../../../utils";
import { GET_PROJECT_BY_ID } from "../../queries";

export const getProjectById =
  ({ companyId, projectId }) =>
  async (dispatch, getState) => {
    const data = await graphQLFetch(
      GET_PROJECT_BY_ID,
      { cId: companyId, pId: projectId },
      "query",
      getState
    );
    const { GetProjectByProjectId } = data || {};
    const currentProject = {
      currentProject: GetProjectByProjectId,
    };

    return dispatch(setProjectById(currentProject || {}));
  };
