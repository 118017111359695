import React from "react";

const Draft: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0L17.145 4.86C17.415 5.025 17.625 5.25 17.775 5.535C17.925 5.82 18 6.12 18 6.435V16.2C18 16.695 17.8238 17.1188 17.4713 17.4713C17.1188 17.8238 16.695 18 16.2 18H1.8C1.305 18 0.88125 17.8238 0.52875 17.4713C0.17625 17.1188 0 16.695 0 16.2V6.435C0 6.12 0.075 5.82 0.225 5.535C0.375 5.25 0.585 5.025 0.855 4.86L9 0ZM9 10.485L16.02 6.3L9 2.115L1.98 6.3L9 10.485ZM9 12.6L1.8 8.28V16.2H16.2V8.28L9 12.6ZM9 16.2H16.2H1.8H9Z"
        fill={color || "white"}
      />
    </svg>
  );
};

export default Draft;
