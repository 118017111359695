import { getAuthDetails, getCognitoUser } from "../helpers/AwsConfig";
import { getCognitoAttributeList, getUserPool } from "../helpers/AwsConfig";

export const signUp = ({
  companyId,
  companyType,
  companyName,
  userEmail,
  userType,
  companyDesc,
  userName,
  password,
  companyTypeId,
}) => {
  return new Promise((resolve) => {
    const attributeList = getCognitoAttributeList({
      companyId,
      companyType,
      companyTypeId,
      companyName,
      email: userEmail,
      userType,
      username: userName,
      companyDesc: companyDesc,
      agent: "none",
    });

    getUserPool().signUp(
      userEmail,
      password,
      attributeList,
      null,
      function (err, result) {
        if (err) {
          return resolve({ statusCode: 422, response: err });
        }
        return resolve({ statusCode: 201, response: "" });
      }
    );
  });
};

export const verifyForgetPassword = (email, code, newPassword, callback) => {
  const cognitoUser = getCognitoUser(email);

  cognitoUser.confirmPassword(code, newPassword, {
    onSuccess: function (result) {
      callback(result);
    },
    onFailure: (err) => {
      callback(err?.message || "Not able to verify the password");
    },
  });
};

export const signIn = (email, password, callback) => {
  const authDetails = getAuthDetails(email, password);

  const user = getCognitoUser(email);

  user.authenticateUser(authDetails, {
    onSuccess: (data) => {
      user.getSession(() => {
        callback({ user });
      });
    },

    onFailure: (err) => {
      const errMessage = err?.message || "Invalid request";
      callback({ errMessage });
    },
  });
};

export const forgotPassword = (email, onSucuss, onError) => {
  const cognitoUser = getCognitoUser(email);
  cognitoUser.forgotPassword({
    onSuccess: function (result) {
      onSucuss();
    },
    onFailure: function (err) {
      console.error("Error initiating forgot password:", err.message);
      onError();
    },
  });
};

export const signOut = ({ user }, callback) => {
  user?.getSession((err: any, result: any) => {
    if (result) {
      user?.globalSignOut({
        onSuccess: (result) => {
          callback();
        },
        onFailure: (err) => {
          console.error("error while logging out", err.message);
          callback();
        },
      });
    }
  });
};
