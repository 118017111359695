import { setTableauToken } from "../set/setTableauToken";
import { graphQLFetch } from "../../../utils";
import { GET_TABLEAU_AUTH } from "../../queries";
import { TableauViz, TableauEventType, Toolbar, ExportPDFOptions, PrintOrientation, PrintPageSize, PrintScaling } from '@tableau/embedding-api';

export const getTableauToken = () => async (dispatch, getState) => {
  const data = await graphQLFetch(
    GET_TABLEAU_AUTH,
    undefined,
    "query",
    getState
  );
  const { getTableauAuth } = data || {};
  const { body } = getTableauAuth;
  const { token = "", url = [] } = JSON.parse(body);

  return dispatch(setTableauToken({ token, url } || {}));
};

export const exportTableauViewToPdf = (projectId: string) => async (dispatch, getState) => {
  // Get the latest token and URL
  await dispatch(getTableauToken());

  const state = getState();
  const { token: tableauToken, url: tableauUrls } = state.getTableauToken || {};
  if (!tableauToken || !tableauUrls) {
    console.error('Tableau token or URLs are not available');
    return;
  }

  // Use the first URL if it's an array
  const viewUrl = Array.isArray(tableauUrls) ? tableauUrls[0] : tableauUrls;

  const pdfOptions: ExportPDFOptions = {
    orientation: PrintOrientation.Landscape,
    pageSize: PrintPageSize.Legal,
    scaling: PrintScaling.Automatic,
  };

  const viz = document.createElement('tableau-viz') as TableauViz;
  viz.src = viewUrl;
  viz.token = tableauToken;
  viz.hideTabs = true;
  viz.toolbar = Toolbar.Hidden;
  viz.addFilter('PROJECT_UUID', projectId);
  viz.width = '1400px';
  viz.height = '800px';

  const vizContainer = document.createElement('div');
  if (vizContainer) {
    vizContainer.style.width = '1400px';
    vizContainer.style.height = '800px';
  }
  document.body.appendChild(vizContainer);
  vizContainer.appendChild(viz);


  // Wait for the viz to load
  try {
    await new Promise(resolve => viz.addEventListener(TableauEventType.FirstInteractive, resolve));
    // The viz is now interactive and ready for export
    const workbook = viz.workbook;
    const sheets = workbook.publishedSheetsInfo;
    const publishedSheetNames = sheets.map((publishedSheetInfo) => publishedSheetInfo.name);
    await viz.exportPDFAsync(publishedSheetNames, pdfOptions);
    await new Promise(resolve => setTimeout(resolve, 400)); // Add a 400ms delay
  } catch (error) {
    console.error('Error exporting PDF:', error);
  } finally {
    document.body.removeChild(vizContainer);
  }
};
