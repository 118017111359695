import { useSelector } from 'react-redux';
import { userInfoSelector } from '../redux';
import { READ_ONLY_USER } from '../constants';

export const useReadOnlyUser = () => {
  const userInfo = useSelector(userInfoSelector) || {};
  const { signInUserSession } = userInfo || {};
  const { idToken } = signInUserSession || {};
  const { payload } = idToken || {};
  const { "custom:userType": customUserType } = payload || {};
  return READ_ONLY_USER.includes(customUserType?.toLowerCase());
};

export default useReadOnlyUser;