import { SET_USER_INFO } from "../../constants";

export const userInfo = (state: any = null, action: any) => {
  switch (action.type) {
    case SET_USER_INFO:
      return action.payload;
    default:
      return state;
  }
};
