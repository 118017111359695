// @ts-nocheck
import React, { useEffect, useState } from "react";

import {
  CustomDialogFooter,
  FormGrid,
  Header,
  Heading,
  SubHeading,
  Conatiner,
  CloseButton,
} from "./UnitInformationModal.styles";

import InputBox from "../../InputField/InputField";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import Calendar from "../../DatePicker/DatePicker";
import { validateDate } from "../../../utils";

const defaultError = {
  "Unit Name": { status: true, message: "" },
  "Number of stories": { status: true, message: "" },
  "Square Feet": { status: true, message: "" },
  "Number of Bedrooms": { status: true, message: "" },
  "Number of Bathrooms": { status: true, message: "" },
};

export const UnitInformationModal: React.FC<{
  isEdit?: Boolean;
  onClose?: any;
  isOpen?: Boolean;
  saveUnit: () => {};
  inputValues?: any;
  setInputValues?: () => {};
  pName: string;
  isBtnLoading?: boolean;
}> = ({
  onClose,
  isEdit,
  isOpen,
  saveUnit = () => {},
  inputValues,
  setInputValues = () => {},
  pName,
  isBtnLoading = false,
}) => {
  const [errorFields, setErrorFields] = useState({ ...defaultError });
  const checkValidation = () => {
    let status = true;
    const error = { ...errorFields };
    for (let name in errorFields) {
      if (inputValues[name].value === "") {
        error[name] = {
          status: inputValues[name].value !== "",
          message: inputValues[name].value === "" ? "*required field" : "",
          size: "12px",
        };
        status = false;
      }
    }
    setErrorFields(error);

    for (let i in inputValues) {
      if (
        inputValues[i].type === "date" &&
        inputValues[i].value !== "" &&
        validateDate(inputValues[i].value).status === false
      ) {
        status = false;
        break;
      }
    }
    return status;
  };

  const saveProductHandler = () => {
    if (checkValidation()) {
      saveUnit();
    }
  };

  const ModalFooter = (
    <CustomDialogFooter>
      <Button type="button" className="btn-default" onClick={onClose}>
        Cancel
      </Button>
      <Button
        type="button"
        className="btn-primary"
        onClick={saveProductHandler}
        isLoading={isBtnLoading}
      >
        Save
      </Button>
    </CustomDialogFooter>
  );

  const resetError = () => {
    setErrorFields({ ...defaultError });
  };

  const onChangeHandler = (event, inputValue) => {
    const { name } = event.target || {};
    setInputValues({
      ...inputValues,
      [name]: { ...inputValues[name], value: inputValue },
    });
    resetError();
  };

  const ModalHeader = (
    <Header>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <div>
        <Heading>{!isEdit ? "Create" : "Edit"} Unit</Heading>
        <SubHeading>{pName}</SubHeading>
      </div>
    </Header>
  );

  return (
    <Conatiner>
      <Modal
        open={isOpen}
        header={ModalHeader}
        hideCloseIcon
        footer={ModalFooter}
        onClose={onClose}
      >
        <FormGrid>
          {Object.entries(inputValues).map(([key, item], index) => {
            const {
              maxLength,
              required,
              type,
              value,
              name,
              step,
              max,
              dataType,
              isDollorIcon,
              regexPattern,
            } = item;
            if (name === "Unit Id") return "";
            if (type === "date") {
              return (
                <div className={`field col`} key={index}>
                  <Calendar
                    name={key}
                    value={value}
                    error={errorFields[key]}
                    onChange={(dateValue) => {
                      onChangeHandler({ target: { name } }, dateValue);
                    }}
                  />
                </div>
              );
            }
            return (
              <div
                className={`field col ${index === 0 ? "row-1" : ""}`}
                key={index}
              >
                <InputBox
                  value={value}
                  name={key}
                  type={type}
                  showLabel
                  required={required}
                  onChange={onChangeHandler}
                  maxLength={maxLength}
                  errorMessage={errorFields[key]}
                  step={step}
                  max={max}
                  dataType={dataType}
                  isDollorIcon={isDollorIcon}
                  regexPattern={regexPattern}
                />
              </div>
            );
          })}
        </FormGrid>
      </Modal>
    </Conatiner>
  );
};

export default UnitInformationModal;
