import { gql } from "@apollo/client";

export const UPDATE_INSPECTIONS = gql`
  mutation UpdateInspections($input: UpdateInspInput) {
    UpdateInsp(input: $input) {
      message
      success
      result
    }
  }
`;
