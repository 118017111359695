import styled from "styled-components";
import {
  CustomLabel,
  InputBoxContainer,
} from "../InputField/InputField.styles";
import { Color } from "../../styles/Colors";

export const AutoCompleteContainer = styled(InputBoxContainer)<{
  error: string;
}>`
  z-index: 99 !important;
  fieldset {
    border: 1px solid
      ${({ error }) => (error ? Color.outlineVarient : Color.error)} !important;
  }
`;

export const Label = styled(CustomLabel)`
  margin-bottom: 8px !important;
`;
