import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const ModalWrapper = styled.div<{ open?: boolean }>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const ModalContent = styled.div`
  background-color: ${Color.white};
  margin: 20% auto;
  padding: 20px;
  border-radius: 5px;
  color: #000;
  width: 500px;
`;

export const ModalHeader = styled.div`
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ModalBody = styled.div`
  padding: 10px;
  position: relative;
`;

export const ModalFooter = styled.div`
  padding: 10px;
`;

export const CloseButton = styled.button`
  order: 1;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background: unset;
  &:hover {
    color: black;
  }
`;
