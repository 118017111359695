import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation createProject($input: ProjectInput!) {
    CreateProject(input: $input) {
      message
      success
      result
    }
  }
`;
