import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 24px;
  align-items: center;
  border-bottom: 1px solid ${Color.outlineVarient};
  background: var(--surface, ${Color.white});
  .button-group {
    display: flex;
    gap: 5px;
  }
  button {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: ${Color.primary};
    padding: 12px 24px;
    color: ${Color.white};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Cards = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  /* height: 782px; */
  flex-direction: column;
  align-items: flex-start;
  background: #f5f8ff;
  overflow:auto;
`;

export const Type = styled.div`
  color: #000;

  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const NoProjects = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
`;
