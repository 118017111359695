import React from "react";
import { FooterContainer, Logo } from "./Footer.styles";
import FooterLogo from "../../assets/images/Common/Vertical_iq_logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <FooterContainer>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Logo>
            <img src={FooterLogo} alt="FooterLogo" />
          </Logo>
          <Link
            to="https://lc-policy-dev.s3.amazonaws.com/Vertical-IQ+Privacy+Policy.pdf"
            target="_blank"
          >
            Copyright &copy; 2024 | Level Capital Vertical IQ
          </Link>
        </div>
        <p>info@vertical-iq.com</p>
      </FooterContainer>
    </footer>
  );
}

export default Footer;
