import { gql } from "@apollo/client";

export const GET_TABLEAU_AUTH = gql`
  query getTableauAuth {
    getTableauAuth {
      statusCode
      body
    }
  }
`;

