import * as React from "react";
import TextField from "@mui/material/TextField";
import ComboBox from "@mui/material/Autocomplete";
import { AutoCompleteContainer, Label } from "./Autocomplete.styles";
import { ErrorMessage } from "../InputField/InputField.styles";

export const AutoComplete = ({
  options,
  loading,
  value,
  name,
  error,
  onChange,
  onBlur,
}) => {
  return (
    <AutoCompleteContainer error={error?.status}>
      {name && <Label>{name}</Label>}
      <ComboBox
        sx={{ marginTop: "5px" }}
        fullWidth
        disableClearable
        loading={loading}
        options={options}
        size="small"
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        onInputChange={(e, value) => {
          onChange(value);
        }}
        onBlur={() => {
          onBlur();
        }}
      />
      {!error?.status && (
        <ErrorMessage errorMessageSize={error?.size}>
          {error?.message}
        </ErrorMessage>
      )}
    </AutoCompleteContainer>
  );
};

export default AutoComplete;
