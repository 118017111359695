import styled from "styled-components";
import BackgroundImage from "../../assets/images/Login/login_Bg.png";
import { Color } from "../../styles/Colors";

export const ParentContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  > div {
    height: 100%;
    overflow: auto;
  }
`;

export const Homecontent = styled.div`
  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  > div {
    display: flex;
    gap: 25px;
    position: relative;
    z-index: 9999;
    height: calc(100vh - 139px);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${Color.primary}bf;
  }
`;

export const WelcomeContainer = styled.div`
  background-color: ${Color.white};
  height: 100%;
  padding: 40px;
  opacity: 0.8;
  width: 50%;
  height: 100%;
  overflow: auto;

  h1 {
    margin-bottom: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    font-family: "Lato";
  }
  p {
    margin: 20px 0;
  }
`;

export const CardsDetail = styled.div`
  width: 50%;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1281px) {
    width: 50%;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    justify-content: unset;
  }
`;
