import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 12px 24px;
  align-items: center;
  border-bottom: 1px solid ${Color.outlineVarient};
  background: var(--surface, ${Color.white});
  button {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: ${Color.primary};
    padding: 12px 24px;
    color: ${Color.white};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Cards = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: #f5f8ff;
  overflow:auto;
`;

export const Type = styled.div`
  color: #000;

  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BackButton = styled.div``;

export const UserFields = styled.form`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 24px;
  margin: 16px;
  background: ${Color.white};
  border: 1px solid ${Color.gray};
  border-radius: 5px;

  > * {
    flex: 1 1 24%;
  }
`;

export const ActionButtons = styled.div`
  margin-left: auto;
  gap: 16px;
  display: flex;
`;

export const CommentField = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  > * {
    width: 50%;
  }
`;
