import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background-color: ${Color.primary};
  padding: 10px 20px;
  position: sticky;
  bottom: 0;
  p {
    color: white;
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  img {
    width: 62px;
    height: 32px;
  }
`;
