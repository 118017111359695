import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const UserFields = styled.form<{ isEdit: boolean }>`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding:${({ isEdit }) => (isEdit ? "none" : "24px")};
  margin: ${({ isEdit }) => (isEdit ? "none" : "32px")};
  background: ${({ isEdit }) => (isEdit ? "none" : Color.white)};
  border: ${({ isEdit }) => (isEdit ? "none" : `1px solid ${Color.gray}`)};
  border-radius:  ${({ isEdit }) => (isEdit ? "0" : "5px")};

  > * {
    flex: ${({ isEdit }) => (isEdit ? "1 1 22%" : "1 1 22%")};
  }
`;
